var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index landing"},[_c('div',{staticClass:"wrap"},[_c('header',{staticClass:"common"},[_c('h1',[_vm._v("Story Work")]),_c('nav',{staticClass:"member"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn() === false),expression:"isLoggedIn() === false"}],attrs:{"to":"/login"}},[_vm._v("로그인")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn()),expression:"isLoggedIn()"}],attrs:{"href":""},on:{"click":_vm.logout}},[_vm._v("로그아웃")]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn() === false),expression:"isLoggedIn() === false"}],attrs:{"to":"/register"}},[_vm._v("회원가입")]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn()),expression:"isLoggedIn()"}],attrs:{"to":"/storage"}},[_vm._v("보관함")]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn()),expression:"isLoggedIn()"}],attrs:{"to":"/gallery"}},[_vm._v("공유갤러리")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn()),expression:"isLoggedIn()"}],attrs:{"href":"https://storygnu.sangmin.eu.org/"}},[_vm._v("메인사이트로")])],1)]),_c('main',[_c('section',{staticClass:"intro"},[_vm._m(0),_vm._m(1),_c('router-link',{staticClass:"start",attrs:{"to":"/editor"}},[_vm._v("스토리워크 사용하기")])],1),_vm._m(2),_c('section',{staticClass:"editor"},[_vm._m(3),_vm._m(4),_c('router-link',{staticClass:"start",attrs:{"to":"/editor"}},[_vm._v("스토리워크 사용하기")])],1),_vm._m(5),_c('section',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"contest"},[_vm._m(6),_vm._m(7),_c('router-link',{staticClass:"start",attrs:{"to":"/editor"}},[_vm._v("스토리워크 사용하기")])],1)]),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("스토리워크")]),_vm._v("는"),_c('br'),_c('strong',[_vm._v("이야기편집기")]),_vm._v("입니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 우리들의 이야기를 효과적으로 전달하는 방법!"),_c('br'),_vm._v(" 스토리워크로 누구나 쉽게 스토리보드를 만들 수 있습니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"slogan"},[_c('dt',[_vm._v("StoryWork "),_c('span',[_vm._v("=")]),_vm._v(" StoryMaker")]),_c('dd',[_vm._v(" 우리들의 다양한 이야기들로 풍성해지는 세상을 꿈꿉니다. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("스토리워크")]),_vm._v("에서"),_c('br'),_c('strong',[_vm._v("상상은 현실")]),_vm._v("이 됩니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 우리가 무엇이든 상상할 수 있듯"),_c('br'),_vm._v(" 스토리워크는 무엇이듯 가능합니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"cartoon"},[_c('p',[_vm._v(" 스토리워크는 스토리보드(콘티)를 누구나 쉽게 만들 수 있는 온라인 편집기 입니다."),_c('br'),_vm._v(" 좌측 메뉴에서 ‘포즈’를 선택하면 저의 다양한 포즈들을 검색 할 수 있습니다."),_c('br'),_vm._v(" 선택한 포즈를 드래그&드롭하면 편집 화면에 포즈가 추가 됩니다."),_c('br'),_vm._v(" 8개의 포인트를 사용하여 더미맨을 원하는 크기로 키우고 줄이는 것도 할 수 있어요."),_c('br'),_vm._v(" 여러가지 모양의 말풍선을 선택하여 화면에 배치하고 사이즈를 조절할 수 있습니다."),_c('br'),_vm._v(" 글상자를 드래그하여 추가하고 원하는 대사를 입력 합니다."),_c('br'),_vm._v(" 참 쉽죠? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("제1회 "),_c('strong',[_vm._v("스토리보드 공모전")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',[_c('dl',[_c('dt',[_vm._v("공모주제")]),_c('dd',[_vm._v(" 10컷 이상 100컷 이내의 재미있는 이야기의 스토리보드(콘티)"),_c('br'),_vm._v(" 개그, 드라마, 동화, 웹툰 등 장르 무관 ")])]),_c('dl',[_c('dt',[_vm._v("공모방법")]),_c('dd',[_vm._v(" http://www.storywork.co.kr에 접속 하여"),_c('br'),_vm._v(" 스토리워크로 콘티 제작 후 공모전 응모 버튼 클릭! ")])]),_c('dl',[_c('dt',[_vm._v("공모대상")]),_c('dd',[_vm._v("제한 없음(한 아이디 당 1개)")])]),_c('dl',[_c('dt',[_vm._v("시상내역")]),_c('dd',[_vm._v(" 대상(1명) 상장 및 상금 100만원"),_c('br'),_vm._v(" 최우수상(1명) 상장 및 상금 50만원"),_c('br'),_vm._v(" 우수상(3명) 상장 및 10만원 상당 타블렛"),_c('br'),_vm._v(" 장려상(10명) 상장 및 2만원 상당 캐릭터 상품 ")])]),_c('dl',[_c('dt',[_vm._v("공모일정")]),_c('dd',[_vm._v(" 접수: 2021.10.10~2021.11.10"),_c('br'),_vm._v(" 발표: 2021.11.20, 스토리워크와 아이러브캐릭터 12월호 게재 및 개별 통보"),_c('br'),_vm._v(" 시상: 2021.11.27, 장소와 시간 추후 고지 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"common",staticStyle:{"position":"relative"}},[_c('ul',{staticClass:"info"},[_c('li',[_vm._v(" 서울 중구 세종대로 14, 5층 523호(남대문로5가, 그랜드센트럴) (주)보물상자 (우)04527 ")]),_c('li',[_vm._v("대표이사 : 송근우")]),_c('li',[_vm._v("사업자등록번호 : 544-88-01808")]),_c('li',[_vm._v("통신판매신고 : 2021-전주덕진-0726")]),_c('li',[_vm._v("storyworkhelp@gmail.com")])]),_c('p',{staticClass:"copyright"},[_vm._v(" COPYRIGHT © "),_c('span',{staticClass:"ci"},[_vm._v("(주)보물상자")]),_vm._v(". "),_c('span',{staticClass:"ww"},[_vm._v("ALL RIGHTS RESERVED.")])])])
}]

export { render, staticRenderFns }