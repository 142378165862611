/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from "uuid";
import { fabric } from "fabric";

import { getElementOffset } from "./utils/point";
import { EVENT_NAMES, Observable } from "./utils/observable";

import Human from "./model/human";

// define a function that can locate the controls.
// this function will be used both for drawing and for interaction.
function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    var x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
        y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
    return fabric.util.transformPoint(
        { x: x, y: y },
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
        )
    );
}

// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,
function actionHandler(eventData, transform, x, y) {
    var polygon = transform.target,
        currentControl = polygon.controls[polygon.__corner],
        mouseLocalPosition = polygon.toLocalPoint(
            new fabric.Point(x, y),
            "center",
            "center"
        ),
        polygonBaseSize = polygon._getNonTransformedDimensions(),
        size = polygon._getTransformedDimensions(0, 0),
        finalPointPosition = {
            x:
                (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
                polygon.pathOffset.x,
            y:
                (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
                polygon.pathOffset.y
        };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
}

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
function anchorWrapper(anchorIndex, fn) {
    return function(eventData, transform, x, y) {
        var fabricObject = transform.target,
            absolutePoint = fabric.util.transformPoint(
                {
                    x:
                        fabricObject.points[anchorIndex].x -
                        fabricObject.pathOffset.x,
                    y:
                        fabricObject.points[anchorIndex].y -
                        fabricObject.pathOffset.y
                },
                fabricObject.calcTransformMatrix()
            ),
            actionPerformed = fn(eventData, transform, x, y),
            //newDim = fabricObject._setPositionDimensions({}),
            polygonBaseSize = fabricObject._getNonTransformedDimensions(),
            newX =
                (fabricObject.points[anchorIndex].x -
                    fabricObject.pathOffset.x) /
                polygonBaseSize.x,
            newY =
                (fabricObject.points[anchorIndex].y -
                    fabricObject.pathOffset.y) /
                polygonBaseSize.y;
        //fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
        return actionPerformed;
    };
}

export const IMAGE_FIT_TYPE = {
    INNER: "inner",
    OUTTER: "outter"
};

export default class Page extends Observable {
    width = 0;
    height = 0;
    element;
    canvas;

    constructor(parentEl, options) {
        super();
        this.width = options.width;
        this.height = options.height;
        this.bleed = options.bleed;

        this.element = this.initElement(parentEl);
        this.canvas = this.initCanvas();

        this.isActive = false;

        if (typeof options !== "undefined") {
            if (
                typeof options.objects !== "undefined" &&
                typeof options.objects.version !== "undefined"
            )
                this.canvas.loadFromJSON(JSON.stringify(options.objects));
            else this.canvas.loadFromJSON(JSON.stringify(options));
            /*
            if (typeof options.objects !== "undefined") {
                this.addObjects(options.objects);
                
            }
            if (typeof options.backgroundColor !== "undefined") {
                this.setBackgroundColor(options.backgroundColor);
            } else {
                this.setBackgroundColor("rgba(255,255,255,1)");
            }
            */
        }
    }

    destroy() {
        this.element.remove();
    }

    focus() {
        this.element.scrollIntoView(false);
    }

    initElement(parentEl) {
        console.log("options width : ", this.width, "height : ", this.height);
        //parentEl.style.width = this.width + "px";
        //parentEl.style.height = this.height + "px";

        const canvasEl = document.createElement("canvas");
        canvasEl.id = "canvasContainer";
        canvasEl.width = this.width;
        canvasEl.height = this.height;

        console.log(
            "page width : ",
            canvasEl.clientWidth,
            " , height : ",
            canvasEl.clientWidth
        );

        this.canvasElement = canvasEl;

        this.id = "id-" + uuidv4();
        //this.id = this.id.replaceAll('-', '');

        const wrapperEl = document.createElement("div");
        wrapperEl.id = "pageContainer-" + this.id;
        //wrapperEl.style.width = canvasEl.width.toString() + "px";
        //wrapperEl.style.height = canvasEl.height.toString() + "px";
        wrapperEl.className = "pageContainer";

        wrapperEl.appendChild(canvasEl);

        parentEl.appendChild(wrapperEl);

        //wrapperEl.addEventListener('dragenter', handleDragEnter, false);
        //wrapperEl.addEventListener('dragover', handleDragOver, false);
        //wrapperEl.addEventListener('dragleave', handleDragLeave, false);
        wrapperEl.addEventListener("drop", e => this.handleDrop(e), false);
        wrapperEl.addEventListener("click", e => this.handleClick(e), false);

        return wrapperEl;
    }

    initCanvas() {
        const canvas = new fabric.Canvas(this.canvasElement);

        canvas.on("drop", e => {
            console.log("canvas drop", e);
        });

        canvas.on("object:added", e => {
            this.fire(EVENT_NAMES.OBJECT.INSERT, e);
        });

        canvas.on("object:moved", e => {
            this.fire(EVENT_NAMES.OBJECT.MOVE, e);
        });

        /*
        canvas.on("object:moving", e => {
            //this.fire(EVENT_NAMES.OBJECT.MOVE, e);
            console.log("canvas object:moving", e);
        });
        */

        canvas.on("object:removed", e => {
            this.fire(EVENT_NAMES.OBJECT.DELETE, e);
        });

        canvas.on("selection:created", e => {
            this.fire(EVENT_NAMES.OBJECT.CLICK, e);
        });

        canvas.on("selection:cleared", e => {});

        canvas.on("selection:updated", e => {
            this.fire(EVENT_NAMES.OBJECT.CLICK, e);
        });

        canvas.on("text:changed", e => {
            this.fire(EVENT_NAMES.TEXT.CHANGE, e);
        });

        canvas.on("text:selection:changed", e => {
            this.fire(EVENT_NAMES.TEXT.CHANGE, e);

            if (e.target) {
                const text = e.target;
                const styles = text.getSelectionStyles(
                    text.selectionStart,
                    text.selectionEnd
                );
            }
        });

        canvas.on("text:editing:entered", e => {
            this.fire(EVENT_NAMES.TEXT.CHANGE, e);
            console.log("text:editing:entered : ", e);
        });

        canvas.on("text:editing:exited", e => {
            this.fire(EVENT_NAMES.TEXT.CHANGE, e);
            console.log("text:editing:exited : ", e);
        });

        /*
    const pageRect = new fabric.Rect({
      left: 0,
      top: 0,
      width: this.width,
      height: this.height,
      stroke: '#00ff00',
      fill: undefined,
      selectable: false,
      hoverCursor: 'normal',
    });
    canvas.add(pageRect);

    const bleedRect = new fabric.Rect({
      left: 0 + this.bleed.left,
      top: 0 + this.bleed.top,
      width: this.width - this.bleed.left - this.bleed.right,
      height: this.height - this.bleed.top - this.bleed.bottom,
      stroke: '#ffff00',
      fill: undefined,
      selectable: false,
      hoverCursor: 'normal',
    });

    canvas.add(bleedRect);
    */

        return canvas;
    }

    handleClick(e) {
        if (!this.isActive) this.fire(EVENT_NAMES.PAGE.CHANGE, {});
    }

    handleDrop(e) {
        e = e || window.event;
        if (e.preventDefault) {
            e.preventDefault();
        }
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        console.log("handleDrop");

        this.canvas.discardActiveObject();

        const transData = e.dataTransfer?.getData("text/plain");

        if (transData) {
            const parseData = JSON.parse(transData);
            switch (parseData.type) {
                case "image":
                case "pose":
                case "decorator":
                    {
                        this.addImage(parseData.data.src, {
                            x: e.clientX,
                            y: e.clientY
                        });
                    }
                    break;
                case "shape":
                case "bubble":
                    {
                        this.addShape(parseData.shapeType, {
                            x: e.clientX,
                            y: e.clientY
                        });
                    }
                    break;

                case "background":
                    {
                        this.setBackgroundImage(parseData.data.src);
                    }
                    break;
            }
        }

        this.fire(EVENT_NAMES.OBJECT.DROP, e);

        return false;
    }

    addShape(type, pt, data) {
        let x = 0;
        let y = 0;

        const targetWidth = this.width / 5;
        const targetHeight = targetWidth;
        if (pt) {
            x = pt.x;
            y = pt.y;
            const offset = getElementOffset(this.element);

            if (offset) {
                y -= offset.top + targetHeight / 2;
                x -= offset.left + targetWidth / 2;
            }
        } else {
            x = this.width / 2 - data.width / 2;
            y = this.height / 2 - data.height / 2;
        }

        if (typeof data === "undefined") {
            data = {
                origin: "center",
                width: targetWidth,
                height: targetHeight,
                angle: 0,
                stroke: "#000000",
                strokeWidth: 1,
                fill: undefined
            };
        }

        let shape = null;
        switch (type) {
            case "Rect":
                shape = new fabric.Rect(data);
                break;
            case "Triangle":
                shape = new fabric.Triangle(data);
                break;
            case "Circle":
                data.radius = 50;
                shape = new fabric.Circle(data);
                break;
            case "Polygon":
                shape = new fabric.Polygon(data);
                break;
            case "Polyline":
                shape = new fabric.Polyline(data);
                break;
        }

        if (shape != null) {
            //shape.clipPath = fabric.util.object.clone(shape);

            /*
      const container = new Container([shape], {
        left: x,
        top: y,
        stroke: '#ff0000',
        strokeWidth: 1,
        width: targetWidth,
        height: targetHeight,
      });
      //container.add(shape);
      this.canvas.add(container);
      
      this.canvas.renderAll();


      this.fire(EVENT_NAMES.OBJECT.INSERT, container);
      */

            this.canvas.add(shape);
            this.canvas.renderAll();
            this.fire(EVENT_NAMES.OBJECT.INSERT, shape);
        }
    }

    addImage(src, pt) {
        let img;

        /*
        if (typeof id === "string") img = document.getElementById(id);
        else img = id;
        */

        const offset = getElementOffset(this.canvasElement);

        fabric.Image.fromURL(
            src,
            image => {
                let x = pt.x;
                let y = pt.y;

                const targetWidth = this.width / 5;
                const targetHeight = (targetWidth * image.height) / image.width;
                if (offset) {
                    y -= offset.top + targetHeight / 2;
                    x -= offset.left + targetWidth / 2;
                }

                image.set({
                    left: x,
                    top: y,
                    angle: 0,
                    padding: 0
                });
                image.scaleToWidth(targetWidth);
                image.scaleToHeight(targetWidth);
                this.canvas.add(image);
                this.canvas.setActiveObject(image);
                this.canvas.renderAll();
                this.fire(EVENT_NAMES.OBJECT.INSERT, image);
            },
            { crossOrigin: "anonymous" }
        );

        /*
        const fImage = new fabric.Image( img, {
            left: x,
            top: y,
            angle: 0,
            padding: 0
        });

        fImage.scaleToWidth(targetWidth);
        fImage.scaleToHeight(targetWidth);
        this.canvas.add(fImage);
        this.canvas.renderAll();
        this.fire(EVENT_NAMES.OBJECT.INSERT, fImage);
        */
    }

    addObjects(objects, refresh) {
        if (typeof refresh === "undefined") refresh = false;

        if (typeof objects !== "undefined" && Array.isArray(objects)) {
            objects.map(object => {
                this.addObejct(object);
            });

            if (refresh) this.canvas.renderAll();
        }
    }

    addObejct(object, refresh) {
        if (typeof refresh === "undefined") refresh = false;

        this.canvas.add(object);

        if (refresh) this.canvas.renderAll();

        this.fire(EVENT_NAMES.OBJECT.INSERT, object);
    }

    /**
     * set background image from url
     * @param {string} url
     */
    setBackgroundImage(url) {
        const there = this;

        /*
        fabric.Image.fromURL(
            url,
            function(img, isError) {
                img.set({
                    width: there.canvas.width,
                    height: there.canvas.height
                });
                there.canvas.setBackgroundImage(
                    img,
                    there.canvas.renderAll.bind(there.canvas)
                );
            },
            { crossOrigin: "anonymous" }
        );
        */

        fabric.Image.fromURL(
            url,
            image => {
                /*
                image.set({
                    originX: "center",
                    originY: "center",
                    centeredScaling: true
                });
                */

                //image.scaleToWidth(this.canvas.width);
                //image.scaleToHeight(this.canvas.height);
                this.canvas.setBackgroundImage(
                    image,
                    () => {
                        there.canvas.renderAll();
                        console.log(
                            "image width : ",
                            image.width,
                            " , height : ",
                            image.height
                        );

                        there.fire(EVENT_NAMES.BACKGROUND.CHANGE, image);
                    },
                    {
                        scaleX: there.canvas.width / image.width,
                        scaleY: there.canvas.height / image.height
                    }
                );
            },
            { crossOrigin: "anonymous" }
        );
    }

    /**
     * background color
     * @param {rgba color} color
     */
    setBackgroundColor(color) {
        this.canvas.setBackgroundColor(
            color,
            this.canvas.renderAll.bind(this.canvas)
        );
    }

    setActive(active) {
        this.isActive = active;
    }

    addHuman(pt, data) {
        let x = 0;
        let y = 0;

        const targetWidth = this.width / 5;
        const targetHeight = targetWidth;
        if (pt) {
            x = pt.x;
            y = pt.y;
            const offset = getElementOffset(this.element);

            if (offset) {
                y -= offset.top + targetHeight / 2;
                x -= offset.left + targetWidth / 2;
            }
        } else {
            x = this.width / 2 - data.width / 2;
            y = this.height / 2 - data.height / 2;
        }

        if (typeof data === "undefined") {
            data = {
                origin: "center",
                width: targetWidth,
                height: targetHeight,
                angle: 0,
                stroke: "#000000",
                strokeWidth: 1,
                fill: undefined
            };
        }

        // const human = new Human(data);
        // this.canvas.add(human);

        const human = new Human({
            width: 100,
            height: 200,
            left: x,
            top: y,
            strokeWidth: 0,
            stroke: "#666"
        });
        this.canvas.add(human);

        this.canvas.requestRenderAll();
        this.fire(EVENT_NAMES.OBJECT.INSERT, human);
        return;

        /*
        var points = [
            {
                x: 3,
                y: 4
            },
            {
                x: 16,
                y: 3
            },
            {
                x: 30,
                y: 5
            },
            {
                x: 25,
                y: 55
            },
            {
                x: 19,
                y: 44
            },
            {
                x: 15,
                y: 30
            },
            {
                x: 15,
                y: 55
            },
            {
                x: 9,
                y: 55
            },
            {
                x: 6,
                y: 53
            },
            {
                x: -2,
                y: 55
            },
            {
                x: -4,
                y: 40
            },
            {
                x: 0,
                y: 20
            }
        ];

        let polygon = new fabric.Polygon(points, {
            left: 100,
            top: 50,
            fill: "#D81B60",
            strokeWidth: 4,
            stroke: "green",
            scaleX: 4,
            scaleY: 4,
            objectCaching: false,
            transparentCorners: false,
            cornerColor: "blue"
        });

        this.canvas.add(polygon);

        this.canvas.setActiveObject(polygon);
        polygon.edit = true;
        if (polygon.edit) {
            var lastControl = polygon.points.length - 1;
            polygon.cornerStyle = "circle";
            polygon.cornerColor = "rgba(0,0,255,0.5)";
            polygon.controls = polygon.points?.reduce(function(
                acc,
                point,
                index
            ) {
                acc["p" + index] = new fabric.Control({
                    positionHandler: polygonPositionHandler,
                    actionHandler: anchorWrapper(
                        index > 0 ? index - 1 : lastControl,
                        actionHandler
                    ),
                    actionName: "modifyPolygon",
                    pointIndex: index
                });
                return acc;
            },
            {});
        } else {
            polygon.cornerColor = "blue";
            polygon.cornerStyle = "rect";
            polygon.controls = fabric.Object.prototype.controls;
        }
        polygon.hasBorders = !polygon.edit;
        this.canvas.requestRenderAll();

        polygon.on("moving", e => {
            console.log("object:movinving ", e);
        });
        */
    }

    addText(txt, options) {
        options = {
            left: this.width / 2,
            top: this.height / 2
        };

        const text = new fabric.Textbox(txt, options);
        this.canvas.add(text);
        this.canvas.setActiveObject(text);
    }

    setTextStyle(object, styleName, value) {
        if (object.setSelectionStyles && object.isEditing) {
            let style = {};
            style[styleName] = value;
            object.setSelectionStyles(style);
        } else {
            let name = styleName;
            object.set(name, value);
        }
    }

    setObjectStyle(object, styleName, value) {
        let name = styleName;
        object.set(name, value);
    }

    getObjectStyle(object, styleName) {
        let name = styleName;
        return object.get(name);
    }

    getTextStyle(object, styleName) {
        let name = styleName;

        if (object.getSelectionStyles && object.isEditing) {
            return object.getSelectionStyles();
        } else {
            return object.get(name);
        }
    }

    setStyle(styleName, value) {
        const targetObject = this.canvas.getActiveObject();

        if (targetObject) {
            if (targetObject.type == "textbox") {
                this.setTextStyle(targetObject, styleName, value);
            } else {
                this.setObjectStyle(targetObject, styleName, value);
            }

            this.canvas.renderAll();
        } else {
            console.log("선택된 객체가 없습니다.");
        }
    }

    getStyle(styleName) {
        const targetObject = this.canvas.getActiveObject();

        if (targetObject.type == "textbox") {
            return this.getTextStyle(targetObject, styleName);
        } else {
            return this.getObjectStyle(targetObject, styleName);
        }
    }

    duplicateObject() {
        const there = this;
        this.canvas.getActiveObject().clone(cloned => {
            there.canvas.discardActiveObject();
            cloned.set({
                left: cloned.left + 10,
                top: cloned.top + 10,
                evented: true
            });
            if (cloned.type === "activeSelection") {
                // active selection needs a reference to the canvas.
                cloned.canvas = there.canvas;
                cloned.forEachObject(function(obj) {
                    there.canvas.add(obj);
                });
                // this should solve the unselectability
                cloned.setCoords();
            } else {
                there.canvas.add(cloned);
            }
            cloned.top += 10;
            cloned.left += 10;
            there.canvas.setActiveObject(cloned);
            there.canvas.requestRenderAll();
        });
    }

    sendBackwards() {
        this.canvas.sendBackwards(this.canvas.getActiveObject());
        this.canvas.renderAll();
    }
    sendToBack() {
        this.canvas.sendToBack(this.canvas.getActiveObject());
        this.canvas.renderAll();
    }
    bringForward() {
        this.canvas.bringForward(this.canvas.getActiveObject());
        this.canvas.renderAll();
    }
    bringToFront() {
        this.canvas.bringToFront(this.canvas.getActiveObject());
        this.canvas.renderAll();
    }
    flipX() {
        const selectedObject = this.canvas.getActiveObject();

        selectedObject.set("flipX", !selectedObject.get("flipX"));
        this.canvas.renderAll();
    }
    flipY() {
        const selectedObject = this.canvas.getActiveObject();

        selectedObject.set("flipY", !selectedObject.get("flipY"));
        this.canvas.renderAll();
    }
    removeSelectedObject() {
        this.canvas.remove(this.canvas.getActiveObject());
        this.canvas.renderAll();
    }

    toJson() {
        const objects = this.canvas.toJSON();
        return {
            width: this.width,
            height: this.height,
            bleed: this.bleed,
            objects: objects
        };
    }

    static fromJson(json) {
        return new Page(json);
    }

    toImage() {
        //this.canvas.deactiveAll().renderAll();
        const dataURL = this.canvas.toDataURL({
            format: "jpg"
        });

        return dataURL;
    }
}
