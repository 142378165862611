import Vue from "vue";
import Router from "vue-router";
import Home from "../pages/Home.vue";
import PageLogin from "../pages/PageLogin.vue";
import PageRegister from "../pages/PageRegister.vue";
import Profile from "../pages/Profile.vue";
import Editor from "../pages/Editor.vue";
//import PageLanding from "../pages/PageLanding.vue";
import PageFileUpload from "../pages/PageFileupload.vue";
import PageUsers from "../pages/admin/PageUsers.vue";
import PagePoses from "../pages/admin/PagePoses.vue";
import PagePoseCategorys from "../pages/admin/PagePosesCategory.vue";
import PageBackgrounds from "../pages/admin/PageBackground.vue";
import PageBackgroundCategory from "../pages/admin/PageBackgroundCategory.vue";

import PageDecos from "../pages/admin/PageDecos.vue";
import PageDecoCategorys from "../pages/admin/PageDecosCategory.vue";

import PageBubbles from "../pages/admin/PageBubbles.vue";
import PageBubbleCategory from "../pages/admin/PageBubblesCategory.vue";

import PageEditorPreivew from "../pages/Preview.vue";
import PageEditorPreivew2 from "../pages/Preview2.vue";

import PageStorage from "../pages/Storage.vue";
import PageGallery from "../pages/gallery.vue";

import PageContest from "../pages/admin/PageContest.vue";
import PageExhibits from "../pages/admin/PageExhibits.vue";

import store from "../store";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        { path: "*", redirect: "/" },
        {
            path: "/",
            component: Home
        },
        {
            path: "/login",
            component: PageLogin
        },
        {
            path: "/register",
            component: PageRegister
        },
        {
            path: "/profile",
            component: Profile,
            meta: { requiresAuth: true }
        },
        {
            path: "/editor",
            component: Editor,
            meta: { requiresAuth: true }
        },
        {
            path: "/editor/:id",
            component: Editor,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: "/editor/:id/preview",
            component: PageEditorPreivew,
            props: true,
            meta: { requiresAuth: false }
        },
        {
            path: "/editor/:id/preview2",
            component: PageEditorPreivew2,
            props: true,
            meta: { requiresAuth: false }
        },
        {
            path: "/storage",
            component: PageStorage,
            meta: { requiresAuth: true }
        },
        {
            path: "/gallery",
            component: PageGallery,
            meta: { requiresAuth: true }
        },
        {
            path: "/upload",
            component: PageFileUpload,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/users",
            component: PageUsers,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/poses",
            component: PagePoses,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/posecategory",
            component: PagePoseCategorys,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/decos",
            component: PageDecos,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/decocategory",
            component: PageDecoCategorys,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/backgrounds",
            component: PageBackgrounds,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/backgroundcategory",
            component: PageBackgroundCategory,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/bubbles",
            component: PageBubbles,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/bubblecategory",
            component: PageBubbleCategory,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/contest",
            component: PageContest,
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/exhibits",
            component: PageExhibits,
            meta: { requiresAuth: true }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
        } else {
            if (to.path !== "/login") next("/login");
            else next();
        }
    } else {
        next();
    }
});

export default router;
