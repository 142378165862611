/* eslint-disable no-unused-vars */
import { fabric } from "fabric";

// define a function that can locate the controls.
// this function will be used both for drawing and for interaction.
function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    var x = fabricObject.controlPoints[this.pointIndex].left,
        y = fabricObject.controlPoints[this.pointIndex].top;
    return fabric.util.transformPoint(
        { x: x, y: y },
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
        )
    );
}

// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,
function actionHandler(eventData, transform, x, y) {
    var targetObject = transform.target,
        currentControl = targetObject.controls[targetObject.__corner],
        mouseLocalPosition = targetObject.toLocalPoint(
            new fabric.Point(x, y),
            "center",
            "center"
        ),
        polygonBaseSize = targetObject._getNonTransformedDimensions(),
        size = targetObject._getTransformedDimensions(0, 0),
        finalPointPosition = {
            left: (mouseLocalPosition.x * polygonBaseSize.x) / size.x,
            top: (mouseLocalPosition.y * polygonBaseSize.y) / size.y
        };
    targetObject.controlPoints[currentControl.pointIndex] = finalPointPosition;
    return true;
}

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
function anchorWrapper(anchorIndex, fn) {
    return function(eventData, transform, x, y) {
        var targetObject = transform.target,
            absolutePoint = fabric.util.transformPoint(
                {
                    x: targetObject.controlPoints[anchorIndex].left,
                    y: targetObject.controlPoints[anchorIndex].top
                },
                targetObject.calcTransformMatrix()
            ),
            actionPerformed = fn(eventData, transform, x, y),
            newDim = targetObject._setPositionDimensions({}),
            polygonBaseSize = targetObject._getNonTransformedDimensions(),
            newX =
                targetObject.controlPoints[anchorIndex].left /
                polygonBaseSize.x,
            newY =
                targetObject.controlPoints[anchorIndex].top / polygonBaseSize.y;
        //targetObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
        return actionPerformed;
    };
}

const Human = fabric.util.createClass(fabric.Object, {
    type: "Human",
    // initialize can be of type function(options) or function(property, options), like for text.
    // no other signatures allowed.
    initialize: function(options) {
        options || (options = {});

        options.objectCaching = false;
        options.transparentCorners = false;
        options.originX = "center";
        options.originY = "center";

        this.callSuper("initialize", options);

        const left = options.left || 0;
        const top = options.top || 0;

        const width = options.width || 100;
        const height = options.height || 100;

        this.lineWidth = 1;

        this.controlPoints = [];
        this.headSize = { width: 5, height: 5 };

        this.initPosition(left, top, width, height);

        //const newDim = this._calcDimensions();
        //this.left = newDim.left;
        //this.top = newDim.top;
        //this.width = newDim.width;
        //this.height = newDim.height;

        this._setPositionDimensions(options);

        this.on("mousedblclick", e => {
            console.log("mousedblclick");
            this.makeEditControls(true);
        });

        this.on("deselected", e => {
            console.log("deselected");
            this.makeEditControls(false);
        });
    },

    initPosition: function(left, top, width, height) {
        const centerX = 0;
        const centerY = 0;

        const armDistance = width / 4;
        const legDistance = height / 4;
        const headDistance = height / 6;
        const waistDistance = height / 8;

        this.headSize = {
            width: (((headDistance * 3) / 4) * 8) / 10,
            height: (headDistance * 3) / 4
        };

        this.controlPoints["body"] = { left: centerX, top: centerY };
        this.controlPoints["waist"] = {
            left: centerX,
            top: centerY + waistDistance
        };
        this.controlPoints["head"] = {
            left: centerX,
            top: centerY - headDistance
        };

        this.controlPoints["leftArm"] = {
            left: centerX - armDistance,
            top: centerY
        };
        this.controlPoints["rightArm"] = {
            left: centerX + armDistance,
            top: centerY
        };
        this.controlPoints["leftLeg"] = {
            left: centerX - armDistance,
            top: centerY + legDistance
        };
        this.controlPoints["rightLeg"] = {
            left: centerX + armDistance,
            top: centerY + legDistance
        };
    },

    toObject: function() {
        return fabric.util.object.extend(this.callSuper("toObject"), {
            //label: this.get('label'),
        });
    },

    _setPositionDimensions: function(options) {
        var calcDim = this._calcDimensions(options),
            correctLeftTop;
        this.width = calcDim.width;
        this.height = calcDim.height;
        if (!options.fromSVG) {
            correctLeftTop = this.translateToGivenOrigin(
                {
                    x: calcDim.left - this.strokeWidth / 2,
                    y: calcDim.top - this.strokeWidth / 2
                },
                "left",
                "top",
                this.originX,
                this.originY
            );
        }
        if (typeof options.left === "undefined") {
            //this.left = options.fromSVG ? calcDim.left : correctLeftTop.x;
            //this.left = calcDim.left;
        }
        if (typeof options.top === "undefined") {
            //this.top = options.fromSVG ? calcDim.top : correctLeftTop.y;
            //this.top = calcDim.top;
        }
        this.pathOffset = {
            x: calcDim.left + this.width / 2,
            y: calcDim.top + this.height / 2
        };
    },

    /**
     * Calculate the polygon min and max point from points array,
     * returning an object with left, top, width, height to measure the
     * polygon size
     * @return {Object} object.left X coordinate of the polygon leftmost point
     * @return {Object} object.top Y coordinate of the polygon topmost point
     * @return {Object} object.width distance between X coordinates of the polygon leftmost and rightmost point
     * @return {Object} object.height distance between Y coordinates of the polygon topmost and bottommost point
     * @private
     */
    _calcDimensions: function() {
        const points = [];
        for (const key in this.controlPoints) {
            points.push(this.controlPoints[key]);
        }

        const minX = fabric.util.array.min(points, "left") || 0,
            minY = fabric.util.array.min(points, "top") || 0,
            maxX = fabric.util.array.max(points, "left") || 0,
            maxY = fabric.util.array.max(points, "top") || 0,
            width = maxX - minX,
            height = maxY - minY;

        return {
            left: minX,
            top: minY,
            width: width,
            height: height
        };
    },

    makeEditControls: function(editable) {
        if (editable) {
            var lastControl = Object.keys(this.controlPoints).length - 1;
            this.cornerStyle = "circle";
            this.cornerColor = "rgba(0,0,255,0.5)";
            this.hasBorders = false;

            this.controls = [];
            let index = 0;
            for (const key in this.controlPoints) {
                const control = new fabric.Control({
                    positionHandler: polygonPositionHandler,
                    actionHandler: anchorWrapper(key, actionHandler),
                    actionName: "modifyHuman",
                    pointIndex: key
                });

                this.controls.push(control);
                index++;
            }

            /*
      this.controls = this.controlPoints.reduce(function (acc, point, index) {
        acc['p' + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
          actionName: 'modifyHuman',
          pointIndex: index,
        });
        return acc;
      }, {});
      */
        } else {
            this.cornerColor = "blue";
            this.cornerStyle = "rect";
            this.controls = fabric.Object.prototype.controls;
            this.hasBorders = true;
        }

        this.canvas.requestRenderAll();
    },

    _render: function(ctx) {
        //this.callSuper('_render', ctx);

        //ctx.save();

        ctx.strokeWidth = this.lineWidth;

        ctx.beginPath();

        // neck
        ctx.moveTo(
            this.controlPoints["body"].left,
            this.controlPoints["body"].top
        );
        ctx.lineTo(
            this.controlPoints["head"].left,
            this.controlPoints["head"].top
        );

        // arms
        ctx.moveTo(
            this.controlPoints["leftArm"].left,
            this.controlPoints["leftArm"].top
        );
        ctx.lineTo(
            this.controlPoints["body"].left,
            this.controlPoints["body"].top
        );
        ctx.lineTo(
            this.controlPoints["rightArm"].left,
            this.controlPoints["rightArm"].top
        );

        //body
        ctx.moveTo(
            this.controlPoints["body"].left,
            this.controlPoints["body"].top
        );
        ctx.lineTo(
            this.controlPoints["waist"].left,
            this.controlPoints["waist"].top
        );

        // legs
        ctx.moveTo(
            this.controlPoints["leftLeg"].left,
            this.controlPoints["leftLeg"].top
        );
        ctx.lineTo(
            this.controlPoints["waist"].left,
            this.controlPoints["waist"].top
        );
        ctx.lineTo(
            this.controlPoints["rightLeg"].left,
            this.controlPoints["rightLeg"].top
        );

        ctx.stroke();

        /*
    var origStrokeStyle = ctx.strokeStyle;
    ctx.strokeStyle = this.stroke || ctx.fillStyle;
    this.stroke && this._renderStroke(ctx);
    ctx.strokeStyle = origStrokeStyle;
    */

        ctx.beginPath();
        ctx.save();
        ctx.transform(
            1,
            0,
            0,
            this.headSize.width / this.headSize.height,
            0,
            0
        );
        ctx.arc(
            this.controlPoints["head"].left,
            this.controlPoints["head"].top,
            this.headSize.width,
            0,
            Math.PI * 2,
            false
        );

        ctx.restore();
        ctx.fillStyle = "gray";
        ctx.fill();

        //ctx.restore();
    }
});

export default Human;
