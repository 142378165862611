<template>
    <!-- BEGIN register -->
    <div class="register">
        <!-- BEGIN register-content -->
        <div class="register-content">
            <form v-on:submit="checkForm" name="register_form">
                <h1 class="text-center">회원가입</h1>
                <!-- <p class="text-muted text-center">
          One Admin ID is all you need to access all the Admin services.
        </p> -->
                <div class="mb-3">
                    <label class="form-label"
                        >이름 <span class="text-danger">*</span></label
                    >
                    <b-input
                        size="lg"
                        class="fs-15px"
                        placeholder="Name place"
                        name="username"
                        v-model="user.userName"
                    ></b-input>
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >이메일 <span class="text-danger">*</span></label
                    >
                    <b-input
                        type="email"
                        size="lg"
                        autocomplete="username"
                        class="fs-15px"
                        placeholder="username@address.com"
                        v-model="user.userId"
                    ></b-input>
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >비밀번호 <span class="text-danger">*</span></label
                    >
                    <b-input
                        type="password"
                        autocomplete="new-password"
                        size="lg"
                        class="fs-15px"
                        v-model="user.password"
                    ></b-input>
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >비밀번호 확인 <span class="text-danger">*</span></label
                    >
                    <b-input
                        type="password"
                        autocomplete="new-password"
                        size="lg"
                        class="fs-15px"
                        v-model="passwordConfirm"
                    ></b-input>
                </div>
                <!--
        <div class="mb-3">
          <label class="form-label"
            >Country <span class="text-danger">*</span></label
          >
          <b-form-select size="lg" class="form-select fs-15px">
            <b-form-select-option value="1" selected
              >United States</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="mb-3">
          <label class="form-label"
            >Gender <span class="text-danger">*</span></label
          >
          <b-form-select size="lg" class="form-select fs-15px">
            <b-form-select-option value="1" selected
              >Female</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="mb-3">
          <label class="form-label"
            >Date of Birth <span class="text-danger">*</span></label
          >
          <b-row>
            <b-col cols="6">
              <b-form-select size="lg" class="form-select fs-15px">
                <b-form-select-option value="1" selected
                  >Month</b-form-select-option
                >
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-form-select size="lg" class="form-select fs-15px">
                <b-form-select-option value="1" selected
                  >Day</b-form-select-option
                >
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-form-select size="lg" class="form-select fs-15px">
                <b-form-select-option value="1" selected
                  >Year</b-form-select-option
                >
              </b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="customCheck1"
            />
            <label class="form-check-label" for="customCheck1"
              >I have read and agree to the <a href="#">Terms of Use</a> and
              <a href="#">Privacy Policy</a>.</label
            >
          </div>
        </div>
		-->
                <div class="mb-3">
                    <button
                        type="submit"
                        class="btn btn-primary btn-lg fs-15px fw-500 d-block w-100"
                    >
                        회원가입
                    </button>
                </div>
                <div class="text-muted text-center">
                    <router-link to="/login">로그인</router-link>
                </div>
            </form>
        </div>
        <!-- END register-content -->
    </div>
    <!-- END register -->
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
 
export default {
    data() {
        return {
            user: {
                userName: "",
                userId: "",
                password: "",
                role: "user"
            },
            passwordConfirm: ""
        };
    },
    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
        next();
    },
    methods: {
        checkForm: function(e) {
            e.preventDefault();
            //this.$router.push({ path: "/dashboard" });
          if (this.user.userName == "") {
              alert("이름을 입력해주세요.");
              return;
            }
  
            if (this.user.userId == "") {
              alert("이메일을 입력해주세요.");
              return;
            }

            if (this.user.password == "") {
              alert("비밀번호를 입력해주세요.");
              return;
            }

            if (this.passwordConfirm == "") {
              alert("비밀번호 확인을 입력해주세요.");
              return;
            }

            if (this.user.password !== this.passwordConfirm) {
              alert("비밀번호가 서로 다릅니다. 확인해주세요.");
              return;
            }


            this.$store
                .dispatch("register", this.user)
                .then(
                    data => {
                        alert("등록에 성공하였습니다.");
                        this.message = data.message;
                        this.successful = true;
                        this.$router.push("/")
                    },
                    error => {
                        this.message = error.message;
                        this.successful = false;
                    }
                )
                //.then(this.$router.push("/"));
        }
    }
};
</script>
