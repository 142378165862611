<template>
    <div class="landing list preview">
        <header class="common">
            <h1>Story Work</h1>
            <h2>
                <strong>{{ userName }}</strong
                >님의 <strong>{{ story.name }}</strong>
            </h2>
        </header>
        <main>
            <ul class="preview">
                <li :key="index" v-for="(preview, index) in previews">
                    <img :src="getPreviewUrl(preview)" />
                </li>
            </ul>
        </main>
    </div>
</template>

<style scoped>
@import "/assets/landing.css";
</style>

<script>
import AppOptions from "../config/AppOptions.vue";

import axios from "axios";
import { API_URL } from "../config/config";

export default {
    name: "Preview",
    props: ["id"],
    metaInfo: { 
    title: "가즈아아아아아",
    link: [{rel: "canonical", href: 'www.storywork.co.kr'}],
    // meta tag 입력하기
    meta: [
        {
        name: 'og:title',
        property: 'og:title',
        content: "스토리워크 에디션",
         vmid: 'og:title',
      },
      {
        name : 'og:title',
        property: "og:url",
        content: process.env.VUE_APP_ROOT,
        vmid: "og:url",
      }
    ]
  },
    data() {
        return {
            story: {},
            previews: [],
            userName: ""
        };
    },
    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;

        next();
    },
    created() {
        const there = this;

        axios
            .get(API_URL + "story/" + this.id)
            .then(res => {
                there.story = res.data;
                there.previews = res.data.previews;
            })
            .catch(e => {
                console.error("Get story Error with : ", e);
            });

        axios
            .get("auth/profile/")
            .then(res => {
                there.userName = res.data.userName;
            })
            .catch(e => {
                console.error("Get story Error with : ", e);
            });
    },
    methods: {
        getPreviewUrl(preview) {
            return API_URL + "file/" + preview;
        }
    }
};
</script>
