<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-3">
            <div>
                <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="javascript:;">ADMIN</a>
                    </li>
                    <li class="breadcrumb-item active">EXHIBITS</li>
                </ul>
                <h1 class="page-header mb-0">Exhibits</h1>
            </div>

            <div class="ms-auto"></div>
        </div>

        <div class="card">
            <div class="tab-content p-4">
                <div class="tab-pane fade show active" id="allTab">
                    <!-- BEGIN input-group -->
                    <div class="input-group mb-4">
                        <div class="flex-fill position-relative">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control ps-35px"
                                    placeholder="Filter"
                                    v-model="searchWord"
                                />
                                <div
                                    class="input-group-text position-absolute top-0 bottom-0 bg-none border-0"
                                    style="z-index: 1020;"
                                >
                                    <i class="fa fa-search opacity-5"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END input-group -->

                    <!-- BEGIN table -->
                    <div class="table-responsive">
                        <b-table
                            striped
                            hover
                            data-pagination="true"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="items"
                            :small="true"
                            :fields="fields"
                            :busy.sync="requesting"
                            :filter="searchWord"
                            :filter-included-fields="filterOn"
                        >
                            <template v-slot:cell(story)="row">
                                <b-button
                                    variant="success"
                                    size="sm"
                                    @click="OpenStory(row)"
                                    class="ml-1"
                                    >Open</b-button
                                >
                            </template>

                            <template v-slot:cell(score)="row">
                                <b-row class="my-1">
                                    <b-col sm="9">
                                        <b-form-input
                                            type="number"
                                            v-model="row.item.score"
                                            min="0"
                                            max="100"
                                        />
                                    </b-col>

                                    <b-col sm="3">
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            @click="ExamStory(row)"
                                            class="ml-1"
                                            >Exam</b-button
                                        >
                                    </b-col>
                                </b-row>
                            </template>

                            <template v-slot:cell(Action)="row">
                                <!--
                                <b-button
                                    variant="info"
                                    size="sm"
                                    @click="modify(row)"
                                    class="ml-1"
                                    >Modify</b-button
                                >
                                -->
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    @click="remove(row)"
                                    class="ml-1"
                                    >Delete</b-button
                                >
                            </template>
                        </b-table>
                    </div>
                    <!-- END table -->
                    <div>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- END #content -->
</template>

<style scoped>
.previewImage {
    width: 100px;
}
</style>

<script>
import "../../scss/styles.scss";
import AppOptions from "../../config/AppOptions.vue";
import { dateFormater } from "../../util/date";
import axios from "axios";
import { API_URL } from "../../config/config";

export default {
    data: function() {
        return {
            perPage: 8,
            currentPage: 1,
            totalRows: 0,

            imageUrl: API_URL,
            exhibit: {
                id: -1,
                user: -1,
                story: -1,
                examiner: -1,
                score: 0
            },

            items: [],
            requesting: false,
            searchWord: null,
            filterOn: ["name"],
            fields: [
                { key: "id", sortable: true },
                { key: "user", sortable: true },
                { key: "story", sortable: true },
                { key: "score", sortable: true },
                {
                    key: "created_at",
                    label: "생성시간",
                    sortable: true,
                    formatter: value => {
                        const curDate = new Date(value);
                        return dateFormater("yyyy-mm-dd H:i:s", curDate);
                    }
                },
                {
                    key: "updated_at",
                    label: "갱신시간",
                    sortable: true,
                    formatter: value => {
                        const curDate = new Date(value);
                        return dateFormater("yyyy-mm-dd H:i:s", curDate);
                    }
                },
                { key: "Action", sortable: false, label: "Action" }
            ]
        };
    },
    mounted() {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
    },

    created() {
        axios
            .get(API_URL + "exhibits")
            .then(res => {
                this.items = res.data;
                this.totalRows = this.items.length;
            })
            .catch(e => {
                console.error("Get exhibits Error with : ", e);
            });

        axios.get("auth/profile/");
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
        next();
    },
    methods: {
        ExamStory(row) {
            const data = {
                score: parseFloat(row.item.score)
            };

            const there = this;
            axios
                .put(`exhibits/${row.item.id}/exam`, data)
                .then(function(res) {
                    console.log("reuslt : ", res.data);

                    there.makeToast("Success");
                })
                .catch(function(err) {
                    console.log("Contest delete error : ", err);
                    there.makeToast("Fail");
                });
        },

        remove(row) {
            if (!window.confirm("복구할수 없습니다. 삭제하시겠습니까?")) return;

            const there = this;

            axios
                .delete(`exhibits/${row.item.id}`)
                .then(function(res) {
                    console.log("reuslt : ", res.data);
                    there.items.splice(
                        there.items.findIndex(item => item.id == row.item.id),
                        1
                    );

                    there.makeToast("Success");
                })
                .catch(function(err) {
                    console.log("Contest delete error : ", err);
                    there.makeToast("Fail");
                });
        },
        OpenStory(row) {
            const targetUrl =
                window.location.origin +
                "/editor/" +
                row.item.story +
                "/preview";

            window.open(targetUrl);
        },
        makeToast(msg) {
            this.$notify({
                group: "notifyApp",
                title: "알림",
                text: msg
            });
        }
    }
};
</script>
