import Vue from "vue";
import App from "./App.vue";

// plugins
//import VueRouter from 'vue-router'
import VueBootstrap from "bootstrap-vue";
import VueCustomScrollbar from "vue-custom-scrollbar";
import VueGtag from "vue-gtag";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueApexCharts from "vue-apexcharts";
import vueHljs from "vue-hljs";
import hljs from "highlight.js";
import DateRangePicker from "vue2-daterange-picker";
import * as VueGoogleMaps from "vue2-google-maps";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import VueColorPicker from "@caohenghu/vue-colorpicker";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueMaskedInput from "vue-maskedinput";
import wysiwyg from "vue-wysiwyg";
import { VueMasonryPlugin } from "vue-masonry";
import Notifications from "vue-notification";
import Meta from 'vue-meta'

import VueClipboard from "vue-clipboard2";

// eslint-disable-next-line no-unused-vars
import axios from "@/plugins/axios";

import router from "./routers";
import store from "./store";

// VueStudio SCSS
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "vue-hljs/dist/style.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

// JS SDK init
const KAKAKO_API_KEY = 'fa6ecac170efcafd0fc0041302bb699b';
window.Kakao.init(KAKAKO_API_KEY);
//

Vue.use(VueBootstrap);
Vue.use(Notifications);
Vue.use(VueClipboard);

Vue.component("vue-custom-scrollbar", VueCustomScrollbar);

Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid', // 변화시키는 구분 값
    refreshOnceOnNavigation: true,
  });
  
Vue.use(VueInsProgressBar, {
    position: "fixed",
    show: true,
    height: "3px"
});

Vue.use(VueGtag, {
    config: {
        id: "G-XY8V3GZCFY"
    }
});

Vue.use(wysiwyg, { maxHeight: "300px" });
Vue.use(vueHljs, { hljs });
Vue.use(VueGoogleMaps, {
    load: {
        key: "",
        libraries: "places"
    }
});
Vue.use(VueMasonryPlugin);

Vue.component("apexchart", VueApexCharts);
Vue.component("date-range-picker", DateRangePicker);
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);
Vue.component("vue-color-picker", VueColorPicker);
Vue.component("vue-masked-input", VueMaskedInput);
Vue.component(VueCountdown.name, VueCountdown);

new Vue({
    render: h => h(App),
    router,
    store
}).$mount("#app");
