import { Point } from "./point";

export class Size {
    constructor(width, height) {
        this.width = width;
        this.height = height;
    }

    get area() {
        return this.width * this.height;
    }

    equal(size) {
        return this.width == size.width && this.height == size.height;
    }
}

export class Rectangle {
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }

    get width() {
        return this.right - this.left;
    }

    get height() {
        return this.bottom - this.top;
    }

    equal(rect) {
        return (
            this.left == rect.left &&
            this.top == rect.top &&
            this.right == rect.right &&
            this.bottom == rect.bottom
        );
    }

    deflate(v, h) {
        this.left += v;
        this.right -= v;
        this.top -= h;
        this.bottom += h;
    }

    inflate(v, h) {
        this.left -= v;
        this.right += v;
        this.top += h;
        this.bottom -= h;
    }

    move(x, y) {
        this.left += x;
        this.right += x;
        this.top += y;
        this.bottom += y;
    }

    moveTo(x, y) {
        const width = this.width;
        const height = this.height;

        this.left = x;
        this.top = y;
        this.right = x + width;
        this.bottom = y + height;
    }
}

export function degreeToRadian(degrees) {
    return (degrees * Math.PI) / 180;
}

export function radianToDegree(radian) {
    return (radian * 180) / Math.PI;
}

export function PtToMM(value) {
    return parseFloat((value * 0.352777778).toFixed(4));
}

export function MMToPt(value) {
    return parseFloat((value * 2.83464566929134).toFixed(4));
}

export function xy2angle(dx, dy) {
    if (dx == 0 && dy == 0) return 0.0;

    // x, y 축 방향 벡터에 대한 각도 처리
    if (dy == 0) {
        if (dx > 0) return 0;
        else return Math.PI;
    }

    if (dx == 0) {
        if (dy > 0) return Math.PI / 2.0;
        // 1/2 PI
        else return (Math.PI / 2.0) * 3; // 3/2 PI
    }

    // 일반각에 대한 각도 처리

    if (dx > 0) {
        if (dy > 0) return Math.atan(dy / dx);
        // 1사분면
        else return Math.atan(dy / dx) + Math.PI * 2; // 2사분면
    } else {
        return Math.atan(dy / dx) + Math.PI; // 3,4 사분면
    }
}

export function rotate(point, radian) {
    let x = point.x;
    let y = point.y;
    let cos_a = Math.cos(radian);
    let sin_a = Math.sin(radian);

    return new Point(x * cos_a - y * sin_a, x * sin_a + y * cos_a);
}

export function translate(point, dx, dy) {
    return new Point(point.x + dx, point.y + dy);
}

export function scale(point, w, h) {
    return new Point(point.x * w, point.y * h);
}

export function rotate_at(center, point, radian) {
    let result = translate(point, -center.x, -center.y);
    result = rotate(result, radian);
    result = translate(result, center.x, center.y);

    return result;
}

export function angle(center, pt1, pt2) {
    if (pt2) {
        pt1 = translate(pt1, -center.x, -center.y);
        pt2 = translate(pt2, -center.x, -center.y);

        let s = xy2angle(pt1.x, pt1.y);
        let e = xy2angle(pt2.x, pt2.y);
        let angle = Math.abs(e - s);

        if (angle < Math.PI) return angle;
        else return Math.PI * 2 - angle;
    } else {
        let point = translate(pt1, -center.x, -center.y);
        return xy2angle(point.x, point.y);
    }
}
