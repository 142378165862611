import Page from "./page";

import { EVENT_NAMES, Observable } from "./utils/observable";

export default class StoryEditor extends Observable {
    constructor(parentEl) {
        super();

        let targetElement;
        if (typeof parentEl === "string") {
            if (parentEl.indexOf("#") < 0) parentEl = "#" + parentEl;
            const target = document.body.querySelector(parentEl);
            if (target != null) {
                targetElement = target;
            }
        } else {
            targetElement = parentEl;
        }
        if (typeof targetElement !== "undefined") {
            this.wrapper = this.initWrapperElement(targetElement);
            //const sidebar = this.initSidebar(targetElement);
            //if (sidebar != null) this.sideBar = sidebar;
        } else {
            throw new Error(`Editor init fail cause target element not found`);
        }

        this.pages = [];
        this.activePageId = "";
    }

    initWrapperElement(parentEl) {
        const element = document.createElement("div");
        element.className = "editorWrapper";
        parentEl.appendChild(element);

        return element;
    }

    deletePage(pageId) {
        const targetIndex = this.pages.findIndex(page => page.id == pageId);
        if (targetIndex > -1) {
            this.pages[targetIndex].destroy();
            this.pages.splice(targetIndex, 1);

            return true;
        }

        return false;
    }

    scrollPage(pageId) {
        const targetIndex = this.pages.findIndex(page => page.id == pageId);
        if (targetIndex > -1) {
            this.pages[targetIndex].focus();
        }
    }

    addPage(options, insertPos) {
        const newPage = new Page(this.wrapper, options);
        let activeId = "";
        if (typeof insertPos !== "undefined") {
            this.pages.splice(insertPos, 0, newPage);
            activeId = this.pages[insertPos].id;
        } else {
            this.pages.push(newPage);
            activeId = newPage.id;
        }

        newPage.on(EVENT_NAMES.PAGE.CHANGE, () => {
            console.log("Page Change event call ", newPage.id);
            if (this.getActivePage().id != newPage.id) {
                this.setActivePage(newPage.id);
            }
        });

        newPage.on(EVENT_NAMES.OBJECT.CLICK, e => {
            this.setActivePage(newPage.id);
            this.fire(EVENT_NAMES.OBJECT.CLICK, e);
        });

        newPage.on(EVENT_NAMES.TEXT.CHANGE, e => {
            this.fire(EVENT_NAMES.TEXT.CHANGE, e);
        });

        newPage.on(EVENT_NAMES.BACKGROUND.CHANGE, e => {
            this.fire(EVENT_NAMES.BACKGROUND.CHANGE, e);
            this.updatePreview(newPage);
        });

        newPage.on(EVENT_NAMES.OBJECT.DROP, () => {
            this.updatePreview(newPage);
            this.setActivePage(newPage.id);
        });

        newPage.on(EVENT_NAMES.OBJECT.MOVE, () => {
            this.updatePreview(newPage);
        });

        newPage.on(EVENT_NAMES.OBJECT.INSERT, () => {
            this.updatePreview(newPage);
        });

        newPage.on(EVENT_NAMES.OBJECT.DELETE, () => {
            this.updatePreview(newPage);
        });

        this.updatePreview({ id: activeId });

        this.setActivePage(activeId);
        //this.activePageId = activeId;
    }

    updatePreview(page) {
        //this.fire(EVENT_NAMES.PREVIEW.UPDATE, { id: page.id, image: page.toImage() });

        console.log("editor updatepreview called");
        this.fire(EVENT_NAMES.PREVIEW.UPDATE, { id: page.id });
    }

    getPreview(id) {
        return this.getPage(id).toImage();
    }

    getPage(id) {
        return this.pages.find(page => page.id == id);
    }

    setActivePage(id) {
        if (typeof id === "number") {
            const pageNum = id;
            if (this.pages.length - 1 < pageNum) return false;
            this.pages.map((page, index) => page.setActive(index == pageNum));
            this.activePageId = this.pages[pageNum].id;
        } else if (typeof id === "string") {
            const targetPage = this.getPage(id);
            if (targetPage) {
                this.pages.map(page => {
                    page.setActive(page.id == id);
                    if (page.id == id) page.focus();
                });
                this.activePageId = id;
            } else {
                return false;
            }
        } else {
            return false;
        }

        this.fire(EVENT_NAMES.PAGE.CHANGE, { id: this.activePageId });
        console.log("setActivePage id : ", this.activePageId);
        return true;
    }

    getActivePage() {
        return this.getPage(this.activePageId);
    }

    duplicateObject() {
        this.getActivePage().duplicateObject();
    }
    sendBackwards() {
        this.getActivePage().sendBackwards();
    }
    sendToBack() {
        this.getActivePage().sendToBack();
    }
    bringForward() {
        this.getActivePage().bringForward();
    }
    bringToFront() {
        this.getActivePage().bringToFront();
    }
    flipX() {
        this.getActivePage().flipX();
    }
    flipY() {
        this.getActivePage().flipY();
    }
    removeSelectedObject() {
        this.getActivePage().removeSelectedObject();
    }

    addText() {
        this.getPage(this.activePageId).addText("글을 입력해주세요.", {
            fontSize: 20,
            backgroundColor: "#fff"
        });
    }

    addHuman() {
        this.getPage(this.activePageId).addHuman();
    }

    addImage(img) {
        this.getPage(this.activePageId).addImage(img);
    }

    setStyle(name, value) {
        this.getPage(this.activePageId).setStyle(name, value);
    }

    save() {
        /*
    const jsons = this.pages.reduce((acc, page, index, []) => {
      const json = page.toJson();
      acc.push(json);
    });
    */

        const jsons = [];
        for (const page of this.pages) {
            jsons.push(page.toJson());
        }

        console.log("editor save result : ", jsons);
        return jsons;
    }

    load(contentsString) {
        const contents = JSON.parse(contentsString);
        for (const content of contents) {
            this.addPage(content);
        }
    }

    saveToImage() {
        const images = [];
        for (const page of this.pages) {
            const pageImage = page.toImage();

            var blobBin = atob(pageImage.split(",")[1]); // base64 데이터 디코딩
            var array = [];
            for (var i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            var file = new Blob([new Uint8Array(array)], { type: "image/png" }); // Blob 생성

            images.push(file);

            //images.push(pageImage);
        }

        /*
        for (const image of images) {
            let file = new File([image], "canvas.png");
            //window.open(URL.createObjectURL(file));

            window.requestFileSystem =
                window.requestFileSystem || window.webkitRequestFileSystem;
            window.requestFileSystem(
                window.PERSISTENT,
                5 * 1024 * 1024 ,
                file
            );
        }
        */

        console.log("editor saveToImage result : ", images);
        return images;
    }
}
