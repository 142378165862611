import parseColor from 'parse-color';

export class RGBColor {
  constructor(red, green, blue) {
    if (typeof green === 'undefined' || typeof blue === 'undefined') {
      throw new Error('RGBColor parse Error');
    } else {
      this.red = red;
      this.green = green;
      this.blue = blue;
    }
  }

  toString() {
    return `rgb(${this.red}, ${this.green},${this.blue})`;
  }

  static parse(str) {
    const parsedColor = parseColor(str);
    return new RGBColor(parsedColor.rgb[0], parsedColor.rgb[1], parsedColor.rgb[2]);
  }

  static fromRGBA(rgba) {
    let alpha = rgba.alpha;

    return new RGBColor(
      (1 - alpha) * rgba.red + alpha * rgba.red,
      (1 - alpha) * rgba.green + alpha * rgba.green,
      (1 - alpha) * rgba.blue + alpha * rgba.blue,
    );
  }
}

export class RGBAColor extends RGBColor {
  constructor(red, green, blue, alpha) {
    super(red, green, blue);
    this.alpha = alpha;
  }

  static parse(str) {
    const parsedColor = parseColor(str);
    return new RGBAColor(
      parsedColor.rgba[0],
      parsedColor.rgba[1],
      parsedColor.rgba[2],
      parsedColor.rgba[3],
    );
  }

  toString() {
    return 'rgba(' + this.red + ',' + this.green + ',' + this.blue + ', ' + this.alpha + ')';
  }
}

export class CMYKColor {
  constructor(cyan, magenta, yellow, black) {
    this.cyan = cyan;
    this.magenta = magenta;
    this.yellow = yellow;
    this.black = black;
  }

  toString() {
    return 'cmyk(' + this.cyan + ',' + this.magenta + ',' + this.yellow + ',' + this.black + ')';
  }

  static parse(str) {
    const parsedColor = parseColor(str);
    return new CMYKColor(
      parsedColor.cmyk[0],
      parsedColor.cmyk[1],
      parsedColor.cmyk[2],
      parsedColor.cmyk[3],
    );
  }

  static fromRGB(rgb) {
    let r = rgb.red / 255;
    let g = rgb.green / 255;
    let b = rgb.blue / 255;

    let k = 1 - Math.max(r, g, b);
    let c = (1 - r - k) / (1 - k);
    let m = (1 - g - k) / (1 - k);
    let y = (1 - b - k) / (1 - k);

    return new CMYKColor(c, m, y, k);
  }

  static fromRGBA(rgba) {
    return CMYKColor.fromRGB(RGBColor.fromRGBA(rgba));
  }
}
