<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-3">
            <div>
                <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="javascript:;">ADMIN</a>
                    </li>
                    <li class="breadcrumb-item active">DECORATORS</li>
                </ul>
                <h1 class="page-header mb-0">Decorators</h1>
            </div>

            <div class="ms-auto">
                <button
                    type="button"
                    class="btn btn-primary me-2"
                    v-b-modal.decoModal
                >
                    <i class="fa fa-plus-circle fa-fw me-1"></i> Create
                    Decorator
                </button>

                <b-modal
                    id="decoModal"
                    title="Create Decorator"
                    no-close-on-backdrop
                    @hidden="resetModal"
                    @ok="handleOk"
                >
                    <form v-on:submit="checkForm" name="register_form">
                        <div class="mb-3">
                            <label class="form-label">Name </label>
                            <b-input
                                size="lg"
                                class="fs-15px"
                                placeholder="Name place"
                                v-model="deco.name"
                            ></b-input>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Category </label>
                            <b-button v-b-toggle.newCategory-collapse size="sm">
                                <i class="fa fa-plus-circle fa-fw me-1"></i>
                            </b-button>
                            <b-form-select
                                class="form-select"
                                v-model="deco.category"
                                :options="categorys"
                                value-field="id"
                                text-field="name"
                            ></b-form-select>
                            <b-collapse id="newCategory-collapse">
                                <label class="form-label" for="newCategoryName"
                                    >Name
                                </label>
                                <b-form-input
                                    v-model="newCategoryName"
                                    id="newCategoryName"
                                />
                                <button type="button" @click="addNewCategory">
                                    추가
                                </button>
                            </b-collapse>
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="defaultFile"
                                >File</label
                            >
                            <input
                                type="file"
                                class="form-control"
                                :state="Boolean(deco.file)"
                                ref="selectFile"
                                @click="resetFile"
                                @change="uploadFile"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                accept="image/png, image/gif, image/jpeg, image/svg+xml"
                            />
                            <img
                                v-if="deco.file != -1"
                                :src="imageUrl + 'file/' + deco.file"
                                class="previewImage"
                            />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Tags </label>
                            <b-form-tags
                                input-id="tags-basic"
                                v-model="deco.tags"
                                :limit="6"
                            ></b-form-tags>
                            <!--
                            <b-input
                                size="lg"
                                class="fs-15px"
                                placeholder="Tags"
                                name="tags"
                                v-model="deco.tags"
                            ></b-input>
                            -->
                        </div>
                    </form>
                </b-modal>
            </div>
        </div>

        <div class="card">
            <div class="tab-content p-4">
                <div class="tab-pane fade show active" id="allTab">
                    <!-- BEGIN input-group -->
                    <div class="input-group mb-4">
                        <div class="flex-fill position-relative">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control ps-35px"
                                    placeholder="Filter"
                                    v-model="searchWord"
                                />
                                <div
                                    class="input-group-text position-absolute top-0 bottom-0 bg-none border-0"
                                    style="z-index: 1020;"
                                >
                                    <i class="fa fa-search opacity-5"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END input-group -->

                    <!-- BEGIN table -->
                    <div class="table-responsive">
                        <b-table
                            striped
                            hover
                            data-pagination="true"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="items"
                            :small="true"
                            :fields="fields"
                            :busy.sync="requesting"
                            :filter="searchWord"
                            :filter-included-fields="filterOn"
                        >
                            <template v-slot:cell(file)="row">
                                <img
                                    :src="imageUrl + 'file/' + row.item.file"
                                    class="previewImage"
                                />
                                <object
                                    :data="imageUrl + 'file/' + row.item.file"
                                    type="image/svg+xml"
                                    width="0"
                                    height="0"
                                ></object>
                            </template>
                            <template v-slot:cell(tags)="row">
                                <span
                                    v-for="tag in row.item.tags"
                                    :key="tag"
                                    style="margin-right:3px"
                                    class="badge bg-transparent border border-dark text-dark fs-12px fw-500 rounded-sm m"
                                >
                                    {{ tag }}
                                </span>
                            </template>
                            <template v-slot:cell(Action)="row">
                                <b-button
                                    variant="info"
                                    size="sm"
                                    @click="modify(row)"
                                    class="ml-1"
                                    >Modify</b-button
                                >
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    @click="remove(row)"
                                    class="ml-1"
                                    >Delete</b-button
                                >
                            </template>
                        </b-table>
                    </div>
                    <!-- END table -->
                    <div>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- END #content -->
</template>

<style scoped>
.previewImage {
    width: 100px;
}
</style>

<script>
import "../../scss/styles.scss";
import AppOptions from "../../config/AppOptions.vue";
import { dateFormater } from "../../util/date";
import axios from "axios";
import { API_URL } from "../../config/config";

export default {
    data: function() {
        const there = this;
        return {
            tempName: "Something wrong",

            perPage: 8,
            currentPage: 1,
            totalRows: 0,

            imageUrl: API_URL,
            deco: {
                user: this.$store.state.user.seq,
                name: "",
                file: -1,
                category: -1,
                tags: []
            },
            showNewCategory: false,
            newCategoryName: "",
            items: [],
            categorys: [],
            requesting: false,
            searchWord: null,
            filterOn: ["name", "tags"],
            fields: [
                { key: "id", sortable: true },
                { key: "name", sortable: true },
                {
                    key: "category",
                    sortable: true,
                    formatter: value => {
                        try {
                            const category = there.categorys.find(
                                category => category.id == value
                            );
                            const categoryName = category.name;

                            return categoryName;
                        } catch (e) {
                            return value;
                        }
                    }
                },
                { key: "user", sortable: true },
                { key: "file", sortable: true },
                { key: "tags", sortable: true },
                {
                    key: "created_at",
                    label: "생성시간",
                    sortable: true,
                    formatter: value => {
                        const curDate = new Date(value);
                        return dateFormater("yyyy-mm-dd H:i:s", curDate);
                    }
                },
                {
                    key: "updated_at",
                    label: "갱신시간",
                    sortable: true,
                    formatter: value => {
                        const curDate = new Date(value);
                        return dateFormater("yyyy-mm-dd H:i:s", curDate);
                    }
                },
                { key: "Action", sortable: false, label: "Action" }
            ]
        };
    },
    mounted() {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
    },

    created() {
        axios
            .get(API_URL + "decorators")
            .then(res => {
                this.items = res.data;
                this.totalRows = this.items.length;
            })
            .catch(e => {
                console.error("Get Decos Error with : ", e);
            });

        axios
            .get(API_URL + "decoratorcategory")
            .then(res => {
                this.categorys = res.data;
            })
            .catch(e => {
                console.error("Get Deco Category Error with : ", e);
            });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
        next();
    },
    methods: {
        resetFile() {
            this.deco.file = -1;
            this.$refs.selectFile.value = "";
        },
        uploadFile() {
            if (0 < this.$refs.selectFile.files.length) {
                const targetFile = this.$refs.selectFile.files[0];
                this.isUploading = true;
                let form = new FormData();
                form.append("type", "deco");
                form.append("file", targetFile);

                const there = this;
                axios
                    .post("file/upload/", form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(res => {
                        there.isUploading = false;

                        console.log("reuslt : ", res.data);
                        there.deco.file = res.data.file.seq;
                    })
                    .catch(err => {
                        console.log("file upload error : ", err);
                        there.isUploading = false;
                    });
            }
        },
        checkForm: function() {
            const there = this;
            if (this.deco.id) {
                // eslint-disable-next-line no-unused-vars
                const { id, created_at, updated_at, user, ...data } = this.deco;

                const prevData = JSON.parse(JSON.stringify(this.deco));

                axios
                    .put("decorators/" + id, data)
                    .then(res => {
                        console.log("reuslt : ", res.data);

                        const index = there.items.findIndex(
                            item => item.id === id
                        );
                        there.$set(there.items, index, prevData);

                        there.makeToast("Success");

                        there.$bvModal.hide("decoModal");
                    })
                    .catch(err => {
                        console.log("Decorator modify error : ", err);

                        there.makeToast("Fail");
                    });
            } else {
                this.deco.user = this.$store.state.user.seq;
                axios
                    .post("decorators/", this.deco)
                    .then(res => {
                        console.log("reuslt : ", res.data);

                        there.items.push(res.data);

                        there.makeToast("Success");

                        there.$bvModal.hide("decoModal");
                    })
                    .catch(err => {
                        console.log("Decorator create error : ", err);

                        there.makeToast("Fail");
                    });
            }
        },

        addNewCategory: function() {
            const there = this;

            //this.deco.user = this.$store.state.user.seq;
            axios
                .post("decocategory/", { name: this.newCategoryName })
                .then(res => {
                    console.log("new category reuslt : ", res.data);

                    there.categorys.push(res.data);
                })
                .catch(err => {
                    console.log("new category create error : ", err);

                    there.makeToast("Fail");
                });
        },
        resetModal() {
            this.deco.name = "";
            this.deco.file = -1;
            this.deco.category = -1;
            this.deco.tags = [];

            this.newCategoryName = "";
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.checkForm();
        },
        modify(row) {
            this.deco = { ...row.item };
            //this.newMasterPlate = { ...row.item };
            this.$bvModal.show("decoModal");
        },

        remove(row) {
            if (!window.confirm("복구할수 없습니다. 삭제하시겠습니까?")) return;

            const there = this;

            axios
                .delete(`decorators/${row.item.id}`)
                .then(function(res) {
                    console.log("reuslt : ", res.data);
                    there.items.splice(
                        there.items.findIndex(item => item.id == row.item.id),
                        1
                    );

                    there.makeToast("Success");
                })
                .catch(function(err) {
                    console.log("Decorator delete error : ", err);
                    there.makeToast("Fail");
                });
        },
        makeToast(msg) {
            this.$notify({
                group: "notifyApp",
                title: "알림",
                text: msg
            });
        }
    }
};
</script>
