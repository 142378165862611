export class Observable {
    constructor() {
        this.__eventListeners = [];
    }

    /**
     * Observes specified event
     * @method observe
     * @param {String} eventName
     * @param {Function} handler
     */
    on(eventName, handler) {
        // one object with key/value pairs was passed
        if (typeof handler === "undefined") {
            if (eventName instanceof Array) {
                for (let prop in eventName) {
                    this.on(prop, eventName[prop]);
                }
            }
        } else {
            if (typeof eventName === "string") {
                if (!this.__eventListeners[eventName]) {
                    this.__eventListeners[eventName] = [];
                }
                this.__eventListeners[eventName].push(handler);
            }
        }
    }

    /**
     * Stops event observing for a particular event handler
     * @method stopObserving
     * @param {String} eventName
     * @param {Function} handler
     */
    off(eventName, handler) {
        if (this.__eventListeners[eventName]) {
            let idx = this.__eventListeners[eventName].indexOf(handler);
            if (idx !== -1) {
                this.__eventListeners[eventName].splice(idx, 1);
            }
        }
    }

    /**
     * Fires event with an optional options object
     * @method fire
     * @param {String} eventName
     * @param {Object} [options]
     */
    fire(eventName, options) {
        var listenersForEvent = this.__eventListeners[eventName];
        if (listenersForEvent) {
            for (var i = 0, len = listenersForEvent.length; i < len; i++) {
                // avoiding try/catch for perf. reasons
                listenersForEvent[i](options || {});
            }
        }
    }
}

export const EVENT_NAMES = {
    OBJECT: {
        CLICK: "00",
        CHANGED: "01",
        CHANGING: "02",
        INSERT: "03",
        DELETE: "04",
        UPDATE: "05",
        MOVE: "06",
        DROP: "07"
    },
    ITEM: {
        CLICK: "10",
        CHANGED: "11",
        CHANGING: "12",
        INSERT: "13",
        DELETE: "14",
        UPDATE: "15",
        DELETEALL: "16"
    },
    PROPERTY: {
        CLICK: "20",
        CHANGED: "21",
        CHANGING: "22",
        INSERT: "23",
        DELETE: "24",
        UPDATE: "25",
        DBCLICK: "26",
        FOCUS: "27"
    },
    MENU: { CLICK: "30", CHANGE: "31" },
    DOCUMENT: {
        SAVE: {
            BEGIN: "400",
            UPDATE: "401",
            COMMIT: "402",
            PREVIEW: "403",
            FAIL: "404"
        },
        LOAD: { START: "410", END: "411", FAIL: "412" }
    },
    NAVI: { CLICK: "50" },
    TX: { BEGIN: "60", PROGRESS: "61", END: "62", ERROR: "63" },
    CONFIG: { BEGIN: "70", PROGRESS: "71", END: "72", ERROR: "73" },
    RESOURCE: { BEGIN: "80", PROGRESS: "81", END: "82", ERROR: "83" },
    TEMPLTE: { BEGIN: "90", PROGRESS: "91", END: "92", ERROR: "93" },
    TEMPLTESET: { BEGIN: "100", PROGRESS: "101", END: "102", ERROR: "103" },
    BOOK: { SCALECHANGE: "111", EDITMODE: "112" },
    WIDGET: { CLOSE: "121", CLICK: "122" },
    RICHTEXT: {
        INVALID: { ALL: "1311", TEXT: "1312", RECT: "1313" },
        REQUEST: "132",
        INSERT: { SUCCESS: "1331", FAIL: "1332" }
    },
    VDP: {
        BEGIN: "140",
        PROGRESS: "141",
        END: "142",
        ERROR: "143",
        DELETE: "144",
        UPDATE: "145",
        ACTION: "146"
    },
    STARTUP: { BEGIN: "150", PROGRESS: "151", END: "152", ERROR: "153" },
    WARNING: { SHOW: "160", HIDE: "161" },
    TEXT: { CHANGE: "201" },
    BACKGROUND: { CHANGE: "301" },
    PREVIEW: { UPDATE: "401" },
    PAGE: { CHANGE: "501" }
};
