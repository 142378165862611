const zero = value => (value.toString().length === 1 ? `0${value}` : value);

export const dateFormater = (format, date) => {
    // const allowForm = date.toString().replace(/\.|\-|\s+/g, '/');
    let newDate;
    if (typeof date !== "undefined") {
        newDate = new Date(date);
    } else {
        newDate = new Date();
    }

    return format.replace(/(YY|yyyy|mm|dd|MM|DD|H|i|s)/g, t => {
        switch (t) {
            case "YY":
                return newDate
                    .getFullYear()
                    .toString()
                    .substr(2, 2);
            case "yyyy":
                return newDate.getFullYear();
            case "mm":
                return newDate.getMonth() + 1;
            case "dd":
                return newDate.getDate();
            case "MM":
                return zero(newDate.getMonth() + 1);
            case "DD":
                return zero(newDate.getDate());
            case "H":
                return zero(newDate.getHours());
            case "i":
                return zero(newDate.getMinutes());
            case "s":
                return zero(newDate.getSeconds());
            default:
                return "";
        }
    });
};
