<template>
    <!-- BEGIN login -->
    <div class="login">
        <!-- BEGIN login-content -->
        <div class="login-content">
            <form v-on:submit="checkForm" name="login_form">
                <h1 class="text-center">로그인</h1>

                <div class="mb-3">
                    <label class="form-label">이메일</label>
                    <b-input
                        size="lg"
                        class="fs-15px"
                        autocomplete="username"
                        placeholder="email address"
                        v-model="userId"
                    ></b-input>
                </div>

                <div class="mb-3">
                    <div class="d-flex">
                        <label class="form-label">비밀번호</label>
                        <!--
                        <b-link a href="#" class="ms-auto text-muted"
                            >Forgot password?</b-link
                        >-->
                    </div>
                    <b-input
                        type="password"
                        size="lg"
                        autocomplete="current-password"
                        class="fs-15px"
                        placeholder="Enter your password"
                        v-model="password"
                    ></b-input>
                </div>

                <div class="mb-3">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                            >Remember me</label
                        >
                    </div>
                </div>

                <button
                    type="submit"
                    class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3"
                >
                    로그인
                </button>
                <div class="text-center text-muted">
                    <router-link to="/register">회원가입</router-link>
                </div>
            </form>
        </div>
        <!-- END login-content -->
    </div>
    <!-- END login -->
</template>

<script>
import AppOptions from "../config/AppOptions.vue";

export default {
    data() {
        return {
            userId: "",
            password: ""
        };
    },
    computed: {
        loggedIn() {
            return this.$store.getters.isLoggedIn;
        }
    },
    created() {
        // reset login status
        this.$store.dispatch("logout");
        /*
        if (this.loggedIn) {
            this.$router.push("/profile");
        }
        */
    },
    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
        next();
    },
    methods: {
        checkForm: function(e) {
            e.preventDefault();

            //this.$router.push({ path: "/dashboard" });
            this.submitted = true;
            const { userId, password } = this;

            if (userId && password) {
                const there = this;
                this.$store
                    .dispatch("login", { userId: userId, password: password })
                    .then(
                        // eslint-disable-next-line no-unused-vars
                        res => {
                            /*
                            if (
                                res.data.status == 201 ||
                                res.data.status == 200
                            ) {
                                there.makeToast("success");
                                this.$router.push("/");
                            } else {
                                there.makeToast(res.data.response.message);
                            }
                            */
                            if(res.data.accessToken){
                                alert("로그인에 성공하였습니다.");
                                there.makeToast("success");
                                this.$router.push("/");
                            }else{
                                alert("사용자정보가 일치하지 않습니다");
                            }
                            
                        },
                        error => {
                            this.loading = false;
                            this.message = error.message;
                            there.makeToast(error.message);
                        }
                    )
                    .catch(e => {
                        there.makeToast(e.data.response.message);
                        console.error("Login Error with : ", e);
                    });
            }
        },
        makeToast(msg) {
            this.$notify({
                group: "notifyApp",
                title: "알림",
                text: msg
            });
        }
    }
};
</script>
