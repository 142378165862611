import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: "",
        token: localStorage.getItem("token") || "",
        user: {}
    },
    mutations: {
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, token, user) {
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = "error";
        },
        auth_profile(state, user) {
            state.user = user;
        },
        logout(state) {
            state.status = "";
            state.token = "";
        }
    },

    actions: {
        // eslint-disable-next-line no-empty-pattern
        notify(context, title, msg) {
            this.$notify({
                group: "notifyApp",
                title: title,
                text: msg
            });
        },
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({
                    url: "auth/login",
                    data: user,
                    method: "POST"
                })
                    .then(resp => {
                        const token = resp.data.accessToken;
                        const user = resp.data.user;
                        localStorage.setItem("token", token);
                        // Add the following line:

                        console.log("Login Token :", token);

                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;

                        console.log(
                            "Login Auth Header :",
                            axios.defaults.headers.common["Authorization"]
                        );

                        commit("auth_success", token, user);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit("auth_error");
                        localStorage.removeItem("token");
                        reject(err);
                    });
            });
        },
        register({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({
                    url: "users",
                    data: user,
                    method: "POST"
                })
                    .then(resp => {
                       
                        // const token = resp.data.accessToken;
                        // const user = resp.data.user;
                        // localStorage.setItem("token", token);
                        // // Add the following line:

                        // axios.defaults.headers.common["Authorization"] =
                        //     "Bearer " + token;
                        // commit("auth_success", token, user);
                        
                        resolve(resp);
                    })
                    .catch(err => {
                        // commit("auth_error", err.response);
                        // localStorage.removeItem("token");
                        alert(err.response.data.message[0]);
                        reject(err);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit("logout");

                if (localStorage.getItem("token")) {
                    localStorage.removeItem("token");
                    delete axios.defaults.headers.common["Authorization"];
                    resolve();
                } else {
                    reject();
                }
            });
        },
        profile({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    url: "auth/profile",

                    method: "GET"
                })
                    .then(resp => {
                        const user = resp.data.user;

                        commit("auth_profile", user);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit("auth_error");
                        reject(err);
                    });
            });
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        token: state => state.token,
        getProfile: state => state.user
    }
});
