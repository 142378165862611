<template>
    <div class="landing list preview">
        <header class="common">
            <h1 @click="moveHome()" >Story Work</h1>
            <h2>
                <strong >
                {{userName}} {{findName(story.user)}} 
                </strong>님의 스토리 "<strong>{{ story.name }}</strong>"
            </h2>
        </header>
        <main>
            <ul class="preview">
                <li :key="index" v-for="(preview, index) in previews">
                    <img :src="getPreviewUrl(preview)" />
                </li>
            </ul>
        </main>
    </div>
</template>

<style scoped>
@import "/assets/landing.css";
</style>

<script>
import AppOptions from "../config/AppOptions.vue";

import axios from "axios";
import { API_URL } from "../config/config";

export default {
    name: "Preview",
    props: ["id"],
    data() {
        return {
            story: {},
            previews: [],
            userName: ""
        };
    },

    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
    },
     beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;

        next();
    },
    created() {
        const there = this;

        axios
            .get(API_URL + "story/" + this.id)
            .then(res => {
                there.story = res.data;
                there.previews = res.data.previews;
            })
            .catch(e => {
                console.error("Get story Error with : ", e);
            });

        
    },
    methods: {
        getPreviewUrl(preview) {
            return API_URL + "file/" + preview;
        },

        findName(id){
        const there = this;

            axios
            .get("users/"+id)
            .then(res => {
               
                there.userName = res.data.userName;
            })
            .catch(e => {
                console.error("Get story Error with : ", e);
            });

        },
        moveHome() {
            if (
                !window.confirm(
                    "메인으로 이동하시겠습니까?"
                )
            )
                return;
            this.$router.push("/");
        }
    }
};
</script>
