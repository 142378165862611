

<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content SWeditor">
        <header class="top">
            <h1>Story Work</h1>
            <a href="" target="_self" class="home" @click="moveHome()"
                >첫화면으로</a
            >
            <button type="button" class="loadBox" @click="moveStorage()">
                보관함
            </button>
            <label class="title"
                ><input type="text" v-model="story.name" placeholder="제목"
            /></label>
            <div class="files">
                <button type="button" class="save" @click="SaveStory">
                    저장
                </button>
                <button type="button" class="share" @click="shareStory">
                    외부공유
                </button>
                <!--button @click="kakaoShare(story.name)">
                <img
                src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
                alt="카카오링크 보내기 버튼"
                />
            </button-->

                <button type="button" class="share2" @click="shareStory2" v-if="this.story.gshared === false">
                    갤러리공유
                </button>
                 <button type="button" class="share2" @click="shareStory3" v-else>
                    갤러리공유취소
                </button>
                <button type="button" class="share" @click="downloadStory">
                    다운로드
                </button>
            </div>
        </header>
        <main>
            <header class="ribon on">
                <div class="left">
                    <button type="button" class="copy" @click="duplicateObject">
                        복제
                    </button>
                    <button type="button" class="front" @click="moveForward">
                        앞으로
                    </button>
                    <button type="button" class="back" @click="moveBack">
                        뒤로
                    </button>
                    <button type="button" class="front2" @click="moveFisrt">
                        맨 앞으로
                    </button>
                    <button type="button" class="back2" @click="moveLast">
                        맨 뒤로
                    </button>
                    <button type="button" class="flipH" @click="flipHor">
                        좌우 반전
                    </button>
                    <button type="button" class="flipV" @click="flipVer">
                        상하 반전
                    </button>
                </div>
                <div class="right">
                    <button type="button" class="del" @click="removeObject">
                        삭제
                    </button>
                </div>
            </header>
            <!--
            <nav class="paging">
                <button type="button" class="prev" disabled>이전</button>
                <button type="button" class="next">다음</button>
            </nav>
            -->
            <section id="editorSection">
                <div id="editorContainer"></div>
            </section>
        </main>
        <aside class="tools">
            <button type="button" class="folding">접기/펴기</button>
            <ul>
                <li class="layout" v-show="isMenuShow('template')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('template') }"
                        @click="menuClick('template')"
                    >
                        컷 구성
                    </button>
                    <div class="option" v-show="isMenuActive('template')">
                        <h2>컷 구성</h2>
                        <ul class="choice" id="previewContainer">
                            <li
                                v-bind:class="{ on: preview.active }"
                                v-for="preview in pagePreviewImages"
                                v-bind:id="preview.id"
                                :key="preview.id"
                            >
                                <div>
                                    <div>
                                        <img
                                            :src="preview.image"
                                            @click="setActivePage(preview.id)"
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    @click="deletePage(preview.id)"
                                    class="close"
                                >
                                    <i></i>닫기
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="character" v-show="isMenuShow('character')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('character') }"
                        @click="menuClick('character')"
                    >
                        등장인물
                    </button>
                    <div class="option" v-show="isMenuActive('character')">
                        <h2>등장인물</h2>
                        <ul class="choice">
                            <li>
                                <button type="button" class="character red">
                                    빨강머리앤
                                </button>
                            </li>
                            <li>
                                <button type="button" class="character orange">
                                    주홍글씨
                                </button>
                            </li>
                            <li>
                                <button type="button" class="character yellow">
                                    노랑나비
                                </button>
                            </li>
                            <li>
                                <button type="button" class="character green">
                                    초록빛바닷물
                                </button>
                            </li>
                        </ul>
                        <footer>
                            <button
                                type="button"
                                class="add"
                                onclick="swo.openPopup('setChar');"
                            >
                                캐릭터 추가
                            </button>
                        </footer>
                    </div>
                </li>
                <li class="pose" v-show="isMenuShow('pose')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('pose') }"
                        @click="menuClick('pose')"
                    >
                        포즈
                    </button>
                    <div class="option" v-show="isMenuActive('pose')">
                        <h2>포즈</h2>
                        <header class="conditions">
                            <div class="search">
                                <input type="text" v-model="poseFilterWord" />
                                <button type="button">검색</button>
                            </div>
                            <div class="category select">
                                <b-form-select
                                    v-model="poseCategory"
                                    :options="poseCategorys"
                                    value-field="id"
                                    text-field="name"
                                    @change="poseCategoryChange"
                                >
                                    <b-form-select-option value="-1"
                                        >전체
                                    </b-form-select-option>
                                </b-form-select>
                            </div>
                        </header>
                        <ul class="choice">
                            <li :key="pose.id" v-for="pose in filterPose()">
                                <figure>
                                    <button type="button">
                                        <img
                                            :src="
                                                imageUrl + 'file/' + pose.file
                                            "
                                            draggable
                                            @dragstart="
                                                imageDragStart($event, 'pose')
                                            "
                                        />
                                    </button>
                                    <figcaption>{{ pose.name }}</figcaption>
                                </figure>
                            </li>
                        </ul>
                        <!--
                        <div class="setting">
                            <h3>포즈 설정</h3>
                            <ul>
                                <li class="opacity">
                                    <dl class="wrap">
                                        <dt>불투명도</dt>
                                        <dd class="slider percentage">
                                            <div class="bar"></div>
                                            <div class="value">
                                                <strong>100</strong
                                                ><span class="unit">%</span>
                                            </div>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="filter">
                                    <dl class="wrap _folding">
                                        <dt>
                                            <button type="button">
                                                필터<i></i>
                                            </button>
                                        </dt>
                                        <dd>
                                            필터 상세
                                        </dd>
                                    </dl>
                                </li>
                                <li class="fill">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label class="switch"
                                                >색상 채우기
                                                <input type="checkbox"/><i></i
                                            ></label>
                                        </dt>
                                        <dd>
                                            <ul class="palette">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background: #ff0000;"
                                                        title="빨강"
                                                    >
                                                        빨강
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff5200;"
                                                        title="주황"
                                                    >
                                                        주황
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffb700;"
                                                        title="오렌지"
                                                    >
                                                        오렌지
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffe30d;"
                                                        title="노랑"
                                                    >
                                                        노랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #91f534;"
                                                        title="연두"
                                                    >
                                                        연두
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #03de3b;"
                                                        title="초록"
                                                    >
                                                        초록
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #00daff;"
                                                        title="하늘색"
                                                    >
                                                        하늘색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0056ff;"
                                                        title="파랑"
                                                    >
                                                        파랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0c04ff;"
                                                        title="남색"
                                                    >
                                                        남색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #6400fe;"
                                                        title="보라"
                                                    >
                                                        보라
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff01d8;"
                                                        title="분홍"
                                                    >
                                                        분홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff0274;"
                                                        title="다홍"
                                                    >
                                                        다홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #000000;"
                                                        title="검정"
                                                    >
                                                        검정
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #5d5d5d;"
                                                        title="암회삭"
                                                    >
                                                        암회삭
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #bcbcbc;"
                                                        title="회색"
                                                    >
                                                        회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffffff;"
                                                        title="흰색"
                                                    >
                                                        흰색
                                                    </button>
                                                </li>
                                            </ul>
                                            <dl>
                                                <dt>강도</dt>
                                                <dd class="slider percentage">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>100</strong
                                                        ><span class="unit"
                                                            >%</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="shadow">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label class="switch"
                                                >그림자
                                                <input type="checkbox"/><i></i
                                            ></label>
                                        </dt>
                                        <dd>
                                            <ul class="palette">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background: #ff0000;"
                                                        title="빨강"
                                                    >
                                                        빨강
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff5200;"
                                                        title="주황"
                                                    >
                                                        주황
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffb700;"
                                                        title="오렌지"
                                                    >
                                                        오렌지
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffe30d;"
                                                        title="노랑"
                                                    >
                                                        노랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #91f534;"
                                                        title="연두"
                                                    >
                                                        연두
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #03de3b;"
                                                        title="초록"
                                                    >
                                                        초록
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #00daff;"
                                                        title="하늘색"
                                                    >
                                                        하늘색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0056ff;"
                                                        title="파랑"
                                                    >
                                                        파랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0c04ff;"
                                                        title="남색"
                                                    >
                                                        남색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #6400fe;"
                                                        title="보라"
                                                    >
                                                        보라
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff01d8;"
                                                        title="분홍"
                                                    >
                                                        분홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff0274;"
                                                        title="다홍"
                                                    >
                                                        다홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #000000;"
                                                        title="검정"
                                                    >
                                                        검정
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #5d5d5d;"
                                                        title="암회삭"
                                                    >
                                                        암회삭
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #bcbcbc;"
                                                        title="회색"
                                                    >
                                                        회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffffff;"
                                                        title="흰색"
                                                    >
                                                        흰색
                                                    </button>
                                                </li>
                                            </ul>
                                            <dl class="direction">
                                                <dt>방향</dt>
                                                <dd class="slider degree">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>0</strong
                                                        ><span class="unit"
                                                            >&deg;</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl class="opacity">
                                                <dt>불투명도</dt>
                                                <dd class="slider percentage">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>100</strong
                                                        ><span class="unit"
                                                            >%</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl class="distance">
                                                <dt>거리</dt>
                                                <dd class="slider px">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>0</strong
                                                        ><span class="unit"
                                                            >px</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl class="blur">
                                                <dt>흐림</dt>
                                                <dd class="slider px">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>0</strong
                                                        ><span class="unit"
                                                            >px</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="gradientMask">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label class="switch"
                                                >그라데이션 마스크
                                                <input type="checkbox"/><i></i
                                            ></label>
                                        </dt>
                                        <dd>
                                            <dl class="type">
                                                <dt>타입</dt>
                                                <dd>
                                                    <label class="shape rect"
                                                        ><input
                                                            type="radio"
                                                            name="gradientShape"
                                                        /><i></i> 사각형</label
                                                    >
                                                    <label class="shape circle"
                                                        ><input
                                                            type="radio"
                                                            name="gradientShape"
                                                        /><i></i> 원</label
                                                    >
                                                    <label class="shape line"
                                                        ><input
                                                            type="radio"
                                                            name="gradientShape"
                                                        /><i></i> 선</label
                                                    >
                                                </dd>
                                            </dl>
                                            <dl class="range">
                                                <dt>범위</dt>
                                                <dd class="slider percentage">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>100</strong
                                                        ><span class="unit"
                                                            >%</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="link">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label class="switch"
                                                >링크
                                                <input type="checkbox"/><i></i
                                            ></label>
                                        </dt>
                                        <dd class="tab">
                                            <label class="tab"
                                                ><input
                                                    type="radio"
                                                    name="linkType"
                                                    value="url"
                                                /><i>URL 이동</i></label
                                            >
                                            <label class="tab"
                                                ><input
                                                    type="radio"
                                                    name="linkType"
                                                    value="page"
                                                /><i>페이지 이동</i></label
                                            >
                                        </dd>
                                        <dd class="tabContents" name="url">
                                            <input
                                                type="text"
                                                placeholder="http://"
                                            />
                                        </dd>
                                        <dd class="tabContents" name="page">
                                            <label class="select">
                                                <select>
                                                    <option
                                                        >페이지1
                                                        (현재페이지)</option
                                                    >
                                                    <option>페이지2</option>
                                                    <option>페이지3</option>
                                                </select>
                                            </label>
                                        </dd>
                                    </dl>
                                </li>
                            </ul>
                            <footer>
                                <button
                                    type="button"
                                    class="cancel"
                                    onclick="swo.deselect();"
                                >
                                    선택 해제
                                </button>
                            </footer>
                        </div>
                        -->
                    </div>
                </li>
                <li class="bg" v-show="isMenuShow('background')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('background') }"
                        @click="menuClick('background')"
                    >
                        배경
                    </button>
                    <div class="option" v-show="isMenuActive('background')">
                        <h2>배경</h2>
                        <header class="conditions">
                            <div class="search">
                                <input
                                    type="text"
                                    v-model="backgroundFilterWord"
                                />
                                <button type="button">검색</button>
                            </div>
                            <div class="category select">
                                <b-form-select
                                    class="form-select"
                                    v-model="backgroundCategory"
                                    :options="backgroundCategorys"
                                    value-field="id"
                                    text-field="name"
                                    @change="backgroundCategoryChange"
                                ></b-form-select>
                            </div>
                        </header>
                        <ul class="choice">
                            <li
                                :key="background.id"
                                v-for="background in filterBackground()"
                            >
                                <figure>
                                    <button type="button">
                                        <img
                                            :src="
                                                imageUrl +
                                                    'file/' +
                                                    background.file
                                            "
                                            class="previewImage"
                                            draggable
                                            @dragstart="
                                                imageDragStart(
                                                    $event,
                                                    'background'
                                                )
                                            "
                                        />
                                    </button>
                                    <figcaption>
                                        {{ background.name }}
                                    </figcaption>
                                </figure>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="text" v-show="isMenuShow('text')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('text') }"
                        @click="menuClick('text')"
                    >
                        글상자
                    </button>
                    <div class="option" v-show="isMenuActive('text')">
                        <h2>글상자</h2>

                        <header class="conditions">
                            <div class="search">
                                <input type="text" />
                                <button type="button">검색</button>
                            </div>
                            <div class="category select">
                                <select>
                                    <option>웹폰트</option>
                                </select>
                            </div>
                        </header>

                        <ul class="choice">
                            <li>
                                <button
                                    type="button"
                                    class="text"
                                    style="font-family: ;"
                                >
                                    나눔고딕<br />10pt
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    class="text"
                                    style="font-family: ;"
                                >
                                    나눔명조<br />10pt
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    class="text"
                                    style="font-family: ;"
                                >
                                    궁서체<br />10pt
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    class="text"
                                    style="font-family: ;"
                                >
                                    나눔손글씨<br />10pt
                                </button>
                            </li>
                        </ul>

                        <div class="setting" style="display:block">
                            <h3>텍스트 설정</h3>
                            <ul>
                                <li class="text">
                                    <b-form-select
                                        class="form-select"
                                        v-model="textProperty.fontFamily"
                                        :options="fontList"
                                        value-field="value"
                                        text-field="name"
                                        @change="onFontNameChange"
                                    >
                                    </b-form-select>

                                    <div class="style">
                                        <div class="size">
                                            <select
                                                id="fontSize"
                                                @change="
                                                    onFontSizeChange($event)
                                                "
                                                v-model="textProperty.fontSize"
                                                class="form-control"
                                                aria-label="크기"
                                            >
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="14">14</option>
                                                <option value="18">18</option>
                                                <option value="24">24</option>
                                                <option value="30">30</option>
                                                <option value="36">36</option>
                                                <option value="48">48</option>
                                                <option value="60">60</option>
                                                <option value="72">72</option>
                                                <option value="96">96</option>
                                                <option value="104">104</option>
                                                <option value="120">120</option>
                                                <option value="144">144</option>
                                            </select>
                                        </div>
                                        <label class="_toggle left">
                                            <input
                                                type="radio"
                                                value="left"
                                                name="textAlign"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.textAlign"
                                            />
                                            <i>align-left</i>
                                        </label>
                                        <label class="_toggle center">
                                            <input
                                                type="radio"
                                                value="center"
                                                name="textAlign"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.textAlign"
                                            />
                                            <i>align-center</i>
                                        </label>
                                        <label class="_toggle right">
                                            <input
                                                type="radio"
                                                value="right"
                                                name="textAlign"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.textAlign"
                                            />
                                            <i>align-right</i>
                                        </label>
                                        <label class="_toggle justify">
                                            <input
                                                type="radio"
                                                value="justify"
                                                name="textAlign"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.textAlign"
                                            />
                                            <i>align-justify</i>
                                        </label>
                                    </div>
                                    <div class="style">
                                        <label class="_toggle bold">
                                            <input
                                                type="checkbox"
                                                value="bold"
                                                name="fontWeight"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="
                                                    textProperty.fontWeight
                                                "
                                                :checked="
                                                    textProperty.fontWeight ==
                                                        'bold'
                                                "
                                                true-value="bold"
                                                false-value="normal"
                                            />
                                            <i>Bold</i>
                                        </label>
                                        <label class="_toggle italic">
                                            <input
                                                type="checkbox"
                                                value="italic"
                                                name="fontStyle"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.fontStyle"
                                                :checked="
                                                    textProperty.fontStyle ==
                                                        'italic'
                                                "
                                                true-value="italic"
                                                false-value="normal"
                                            />
                                            <i>Italic</i>
                                        </label>
                                        <label class="_toggle underline">
                                            <input
                                                type="checkbox"
                                                name="underline"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.underline"
                                                :checked="
                                                    textProperty.underline ==
                                                        true
                                                "
                                            />
                                            <i>Underline</i>
                                        </label>
                                        <label class="_toggle strike">
                                            <input
                                                type="checkbox"
                                                name="linethrough"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="
                                                    textProperty.linethrough
                                                "
                                                :checked="
                                                    textProperty.linethrough ==
                                                        true
                                                "
                                            />
                                            <i>Strike</i>
                                        </label>
                                        <!--
                                        <label class="_toggle sup">
                                            <input type="checkbox" />
                                            <i>Sup</i>
                                        </label>
                                        <label class="_toggle sub">
                                            <input type="checkbox" />
                                            <i>Sub</i>
                                        </label>
                                        <label class="_toggle uppercase">
                                            <input type="checkbox" />
                                            <i>Uppercase</i>
                                        </label>
                                        <button type="button" class="reset">
                                            <i>Reset</i>
                                        </button>
                                        -->
                                    </div>
                                </li>
                                <li class="opacity">
                                    <dl class="wrap">
                                        <dt>불투명도</dt>
                                        <dd class="slider percentage">
                                            <input
                                                type="range"
                                                min="0"
                                                max="1"
                                                step="0.01"
                                                name="opacity"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.opacity"
                                            />

                                            <input
                                                type="text"
                                                size="3"
                                                name="opacity"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                                v-model="textProperty.opacity"
                                                disabled
                                                style="width:30px"
                                            />
                                        </dd>
                                    </dl>
                                </li>
                                <li class="color">
                                    <dl class="wrap">
                                        <dt>글자색</dt>
                                        <dd>
                                            <input
                                                type="color"
                                                name="fill"
                                                value="#000000"
                                                v-model="textProperty.fill"
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                            />
                                            <ul class="palette" v-if="false">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background: #ff0000;"
                                                        title="빨강"
                                                    >
                                                        빨강
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff5200;"
                                                        title="주황"
                                                    >
                                                        주황
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffb700;"
                                                        title="오렌지"
                                                    >
                                                        오렌지
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffe30d;"
                                                        title="노랑"
                                                    >
                                                        노랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #91f534;"
                                                        title="연두"
                                                    >
                                                        연두
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #03de3b;"
                                                        title="초록"
                                                    >
                                                        초록
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #00daff;"
                                                        title="하늘색"
                                                    >
                                                        하늘색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0056ff;"
                                                        title="파랑"
                                                    >
                                                        파랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0c04ff;"
                                                        title="남색"
                                                    >
                                                        남색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #6400fe;"
                                                        title="보라"
                                                    >
                                                        보라
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff01d8;"
                                                        title="분홍"
                                                    >
                                                        분홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff0274;"
                                                        title="다홍"
                                                    >
                                                        다홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #000000;"
                                                        title="검정"
                                                    >
                                                        검정
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #5d5d5d;"
                                                        title="암회색"
                                                    >
                                                        암회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #bcbcbc;"
                                                        title="회색"
                                                    >
                                                        회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffffff;"
                                                        title="흰색"
                                                    >
                                                        흰색
                                                    </button>
                                                </li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="color">
                                    <dl class="wrap">
                                        <dt>배경색</dt>
                                        <dd>
                                            <input
                                                type="color"
                                                name="textBackgroundColor"
                                                value="#000000"
                                                v-model="
                                                    textProperty.textBackgroundColor
                                                "
                                                @change="
                                                    onPropertyChange($event)
                                                "
                                            />
                                            <ul class="palette" v-if="false">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background: #ff0000;"
                                                        title="빨강"
                                                    >
                                                        빨강
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff5200;"
                                                        title="주황"
                                                    >
                                                        주황
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffb700;"
                                                        title="오렌지"
                                                    >
                                                        오렌지
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffe30d;"
                                                        title="노랑"
                                                    >
                                                        노랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #91f534;"
                                                        title="연두"
                                                    >
                                                        연두
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #03de3b;"
                                                        title="초록"
                                                    >
                                                        초록
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #00daff;"
                                                        title="하늘색"
                                                    >
                                                        하늘색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0056ff;"
                                                        title="파랑"
                                                    >
                                                        파랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0c04ff;"
                                                        title="남색"
                                                    >
                                                        남색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #6400fe;"
                                                        title="보라"
                                                    >
                                                        보라
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff01d8;"
                                                        title="분홍"
                                                    >
                                                        분홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff0274;"
                                                        title="다홍"
                                                    >
                                                        다홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #000000;"
                                                        title="검정"
                                                    >
                                                        검정
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #5d5d5d;"
                                                        title="암회삭"
                                                    >
                                                        암회삭
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #bcbcbc;"
                                                        title="회색"
                                                    >
                                                        회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffffff;"
                                                        title="흰색"
                                                    >
                                                        흰색
                                                    </button>
                                                </li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="textAdjust" v-if="false">
                                    <dl class="wrap _folding">
                                        <dt>
                                            <button type="button">
                                                글자조정<i></i>
                                            </button>
                                        </dt>
                                        <dd>
                                            <dl>
                                                <dt>자간</dt>
                                                <dd
                                                    class="slider letterSpacing"
                                                >
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>0</strong
                                                        ><span class="unit"
                                                            >em</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>행간</dt>
                                                <dd class="slider lineHeight">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>1.5</strong
                                                        ><span class="unit"
                                                            >em</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>장평</dt>
                                                <dd class="slider percentage">
                                                    <div class="bar"></div>
                                                    <div class="value">
                                                        <strong>100</strong
                                                        ><span class="unit"
                                                            >%</span
                                                        >
                                                    </div>
                                                </dd>
                                            </dl>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="outline" v-if="false">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label
                                                ><input
                                                    type="checkbox"
                                                /><i></i> 외곽선</label
                                            >
                                        </dt>
                                        <dd>
                                            <ul class="palette">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background: #ff0000;"
                                                        title="빨강"
                                                    >
                                                        빨강
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff5200;"
                                                        title="주황"
                                                    >
                                                        주황
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffb700;"
                                                        title="오렌지"
                                                    >
                                                        오렌지
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffe30d;"
                                                        title="노랑"
                                                    >
                                                        노랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #91f534;"
                                                        title="연두"
                                                    >
                                                        연두
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #03de3b;"
                                                        title="초록"
                                                    >
                                                        초록
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #00daff;"
                                                        title="하늘색"
                                                    >
                                                        하늘색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0056ff;"
                                                        title="파랑"
                                                    >
                                                        파랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0c04ff;"
                                                        title="남색"
                                                    >
                                                        남색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #6400fe;"
                                                        title="보라"
                                                    >
                                                        보라
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff01d8;"
                                                        title="분홍"
                                                    >
                                                        분홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff0274;"
                                                        title="다홍"
                                                    >
                                                        다홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #000000;"
                                                        title="검정"
                                                    >
                                                        검정
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #5d5d5d;"
                                                        title="암회색"
                                                    >
                                                        암회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #bcbcbc;"
                                                        title="회색"
                                                    >
                                                        회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffffff;"
                                                        title="흰색"
                                                    >
                                                        흰색
                                                    </button>
                                                </li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="shadow" v-if="false">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label
                                                ><input
                                                    type="checkbox"
                                                /><i></i> 그림자</label
                                            >
                                        </dt>
                                        <dd>
                                            <ul class="palette">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background: #ff0000;"
                                                        title="빨강"
                                                    >
                                                        빨강
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff5200;"
                                                        title="주황"
                                                    >
                                                        주황
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffb700;"
                                                        title="오렌지"
                                                    >
                                                        오렌지
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffe30d;"
                                                        title="노랑"
                                                    >
                                                        노랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #91f534;"
                                                        title="연두"
                                                    >
                                                        연두
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #03de3b;"
                                                        title="초록"
                                                    >
                                                        초록
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #00daff;"
                                                        title="하늘색"
                                                    >
                                                        하늘색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0056ff;"
                                                        title="파랑"
                                                    >
                                                        파랑
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #0c04ff;"
                                                        title="남색"
                                                    >
                                                        남색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #6400fe;"
                                                        title="보라"
                                                    >
                                                        보라
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff01d8;"
                                                        title="분홍"
                                                    >
                                                        분홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ff0274;"
                                                        title="다홍"
                                                    >
                                                        다홍
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #000000;"
                                                        title="검정"
                                                    >
                                                        검정
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #5d5d5d;"
                                                        title="암회삭"
                                                    >
                                                        암회삭
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #bcbcbc;"
                                                        title="회색"
                                                    >
                                                        회색
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background: #ffffff;"
                                                        title="흰색"
                                                    >
                                                        흰색
                                                    </button>
                                                </li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="gradient" v-if="false">
                                    <dl class="wrap _switch">
                                        <dt>
                                            <label
                                                ><input
                                                    type="checkbox"
                                                /><i></i> 그라데이션</label
                                            >
                                        </dt>
                                        <dd>
                                            <ul class="palette">
                                                <li class="on">
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#000,#ff5200);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#ff5200,#ffb700);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#ffb700,#ffe30d);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#ffe30d,#91f534);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#91f534,#03de3b);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#03de3b,#00daff);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#00daff,#0056ff);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#0056ff,#0c04ff);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#0c04ff,#6400fe);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#6400fe,#ff01d8);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#ff01d8,#ff0274);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#ff0274,#000000);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#000000,#5d5d5d);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#5d5d5d,#bcbcbc);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#bcbcbc,#bcbcbc);"
                                                    ></button>
                                                </li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        style="background-image: linear-gradient(#bcbcbc,#ffffff);"
                                                    ></button>
                                                </li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                            </ul>
                            <footer>
                                <button
                                    type="button"
                                    class="cancel"
                                    @click="addText"
                                >
                                    글상자 추가
                                </button>
                            </footer>
                        </div>
                    </div>
                </li>
                <li class="bubble" v-show="isMenuShow('bubble')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('bubble') }"
                        @click="menuClick('bubble')"
                    >
                        말풍선
                    </button>
                    <div class="option" v-show="isMenuActive('bubble')">
                        <h2>말풍선</h2>
                        <ul class="choice">
                            <li :key="bubble.id" v-for="bubble in bubbles">
                                <figure>
                                    <button type="button">
                                        <img
                                            :src="
                                                imageUrl + 'file/' + bubble.file
                                            "
                                            draggable
                                            @dragstart="
                                                imageDragStart($event, 'bubble')
                                            "
                                        />
                                    </button>
                                    <figcaption></figcaption>
                                </figure>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="item" v-show="isMenuShow('decorator')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('decorator') }"
                        @click="menuClick('decorator')"
                    >
                        아이템
                    </button>
                    <div class="option" v-show="isMenuActive('decorator')">
                        <h2>아이템</h2>
                        <header class="conditions">
                            <div class="search">
                                <input
                                    type="text"
                                    v-model="decoratorFilterWord"
                                />
                                <button type="button">검색</button>
                            </div>
                            <div class="category select">
                                <b-form-select
                                    class="form-select"
                                    v-model="decoratorCategory"
                                    :options="decoratorCategorys"
                                    value-field="id"
                                    text-field="name"
                                    @change="decoratorCategoryChange"
                                ></b-form-select>
                            </div>
                        </header>
                        <ul class="choice">
                            <li
                                :key="decorator.id"
                                v-for="decorator in filterDecorator()"
                            >
                                <figure>
                                    <button type="button">
                                        <img
                                            :src="
                                                imageUrl +
                                                    'file/' +
                                                    decorator.file
                                            "
                                            class="previewImage"
                                            draggable
                                            @dragstart="
                                                imageDragStart(
                                                    $event,
                                                    'decorator'
                                                )
                                            "
                                        />
                                    </button>
                                    <figcaption>
                                        {{ decorator.name }}
                                    </figcaption>
                                </figure>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="myImage" v-show="isMenuShow('image')">
                    <button
                        type="button"
                        :class="{ active: isMenuActive('image') }"
                        @click="menuClick('image')"
                    >
                        My이미지
                    </button>
                    <div class="option" v-show="isMenuActive('image')">
                        <h2>My이미지</h2>
                        <header class="conditions">
                            <div class="search">
                                <input
                                    type="text"
                                    v-model="userImageFilterWord"
                                />
                                <button type="button">검색</button>
                            </div>
                        </header>
                        <ul class="choice">
                            <li
                                :key="userImage.id"
                                v-for="userImage in filterUserImage()"
                            >
                                <figure>
                                    <button type="button">
                                        <img
                                            :src="
                                                imageUrl +
                                                    'file/' +
                                                    userImage.seq
                                            "
                                            draggable
                                            @dragstart="
                                                imageDragStart($event, 'image')
                                            "
                                        />
                                    </button>
                                    <figcaption>
                                        {{ userImage.original_name }}
                                    </figcaption>
                                </figure>
                                <button
                                    type="button"
                                    class="del"
                                    @click="deleteUserImage($event, userImage)"
                                >
                                    삭제
                                </button>
                            </li>
                        </ul>
                        <footer>
                            <input
                                type="file"
                                ref="selectFile"
                                class="form-control-file"
                                id="loadImage"
                                @change="uploadFile"
                                v-show="false"
                            />
                            <button type="button" @click="uploadFileClick()">
                                내 파일 업로드
                            </button>
                        </footer>
                    </div>
                </li>
                <li class="addPage">
                    <button type="button" @click="addPage()">
                        페이지<br />추가
                    </button>
                </li>
            </ul>
        </aside>
        <div class="popupMask" v-bind:class="{ on: CheckShowPopup() }">
            <section
                class="popup layout"
                v-bind:class="{ on: showPopups.layout }"
                name="layout"
            >
                <header class="popup">
                    <h2>편집화면 구성을 선택해주세요</h2>
                </header>
                <ul class="layouts">
                    <li>
                        <label
                            ><input type="radio" name="layout"/><i></i>
                            <div><div></div></div
                        ></label>
                    </li>
                    <li>
                        <label
                            ><input type="radio" name="layout"/><i></i>
                            <div>
                                <div></div>
                                <div></div></div
                        ></label>
                    </li>
                    <li>
                        <label
                            ><input type="radio" name="layout"/><i></i>
                            <div>
                                <div></div>
                                <div></div>
                                <div></div></div
                        ></label>
                    </li>
                </ul>
                <label class="title"
                    >스토리보드 제목<br /><input
                        type="text"
                        title="스토리보드 제목"
                /></label>
                <footer class="actions">
                    <dl>
                        <dt>
                            <button
                                type="button"
                                class="start"
                                onclick="swo.start();"
                            >
                                편집 시작
                            </button>
                        </dt>
                        <dd></dd>
                    </dl>
                    <dl>
                        <dt>
                            <button
                                type="button"
                                class="setChar"
                                onclick="swo.openPopup('setCharInit', true);"
                            >
                                등장인물 설정
                            </button>
                        </dt>
                        <dd>
                            등장인물 이름표를 생성하고 편집을 시작합니다 &gt;
                        </dd>
                    </dl>
                </footer>
            </section>
            <section
                class="popup intro "
                v-bind:class="{ on: showPopups.intro }"
                name="intro"
            >
                <figure>
                    <img src="" />
                </figure>
                <footer class="actions">
                    <dl>
                        <dt>
                            <button
                                type="button"
                                class="start"
                                onclick="swo.start();"
                            >
                                편집 시작
                            </button>
                        </dt>
                        <dd></dd>
                    </dl>
                    <dl>
                        <dt>
                            <button
                                type="button"
                                class="setChar"
                                onclick="swo.openPopup('setCharInit', true);"
                            >
                                등장인물 설정
                            </button>
                        </dt>
                        <dd>
                            등장인물 이름표를 생성하고 편집을 시작합니다 &gt;
                        </dd>
                    </dl>
                </footer>
            </section>
            <section
                class="popup setChar"
                v-bind:class="{ on: showPopups.setCharInit }"
                name="setCharInit"
            >
                <header class="popup">
                    <h2>스토리보드 등장인물을 등록해주세요</h2>
                </header>
                <ul class="characters">
                    <li class="red">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                        />
                        <label class="select">
                            <select>
                                <option value="red">빨강</option>
                                <option value="orange">주황</option>
                                <option value="yellow">노랑</option>
                                <option value="green">초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="add">추가</button>
                    </li>
                    <li class="original red">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                        />
                        <label class="select">
                            <select>
                                <option value="red" selected>빨강</option>
                                <option value="orange">주황</option>
                                <option value="yellow">노랑</option>
                                <option value="green">초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="del">제거</button>
                    </li>
                </ul>
                <footer class="actions">
                    <dl>
                        <dt>
                            <button
                                type="button"
                                class="start"
                                onclick="swo.start(this);"
                            >
                                편집 시작
                            </button>
                        </dt>
                        <dd></dd>
                    </dl>
                </footer>
            </section>
            <section
                class="popup setChar"
                v-bind:class="{ on: showPopups.setChar }"
                name="setChar"
            >
                <header class="popup">
                    <h2>스토리보드 등장인물 설정</h2>
                </header>
                <ul class="characters">
                    <li class="red">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                            value="빨강머리앤"
                        />
                        <label class="select">
                            <select>
                                <option value="red">빨강</option>
                                <option value="orange">주황</option>
                                <option value="yellow">노랑</option>
                                <option value="green">초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="add">추가</button>
                    </li>
                    <li class="original red">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                        />
                        <label class="select">
                            <select>
                                <option value="red" selected>빨강</option>
                                <option value="orange">주황</option>
                                <option value="yellow">노랑</option>
                                <option value="green">초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="del">제거</button>
                    </li>
                    <li class="orange">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                            value="주홍글씨"
                        />
                        <label class="select">
                            <select>
                                <option value="red">빨강</option>
                                <option value="orange" selected>주황</option>
                                <option value="yellow">노랑</option>
                                <option value="green">초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="del">제거</button>
                    </li>
                    <li class="yellow">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                            value="노랑나비"
                        />
                        <label class="select">
                            <select>
                                <option value="red">빨강</option>
                                <option value="orange">주황</option>
                                <option value="yellow" selected>노랑</option>
                                <option value="green">초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="del">제거</button>
                    </li>
                    <li class="green">
                        <input
                            type="text"
                            placeholder="등장인물의 이름을 입력해주세요."
                            value="초록빛바닷물"
                        />
                        <label class="select">
                            <select>
                                <option value="red">빨강</option>
                                <option value="orange">주황</option>
                                <option value="yellow">노랑</option>
                                <option value="green" selected>초록</option>
                                <option value="blue">파랑</option>
                                <option value="purple">보라</option>
                            </select>
                        </label>
                        <button type="button" class="del">제거</button>
                    </li>
                </ul>
                <footer class="actions">
                    <button
                        type="button"
                        class="start"
                        onclick="swo.closePopup('setChar');"
                    >
                        적용
                    </button>
                    <button
                        type="button"
                        class="start"
                        onclick="swo.closePopup('setChar');"
                    >
                        취소
                    </button>
                </footer>
            </section>
            <section
                class="popup loadBox"
                v-bind:class="{ on: showPopups.loadBox }"
                name="loadBox"
            >
                <header class="popup">
                    <h2><strong>보물상자</strong>님의 편집 보관함</h2>
                    <button
                        type="button"
                        class="close"
                        @click="showPopups.layout = false"
                    >
                        <i></i>닫기
                    </button>
                </header>
                <ul class="storage">
                    <li>
                        <dl>
                            <dt>사용 중</dt>
                            <dd>34</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>저장 공간</dt>
                            <dd>200</dd>
                        </dl>
                    </li>
                </ul>
                <ul class="loads">
                    <li>
                        <label class="radio">
                            <input type="radio" name="load" />
                            <div>
                                <img src=" " />
                                <h3>스토리보드1</h3>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="load" />
                            <div>
                                <img src=" " />
                                <h3>스토리보드2</h3>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="load" />
                            <div>
                                <img src=" " />
                                <h3>스토리보드3</h3>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="load" />
                            <div>
                                <img src=" " />
                                <h3>스토리보드4</h3>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="load" />
                            <div>
                                <img src=" " />
                                <h3>스토리보드5</h3>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="load" />
                            <div>
                                <img src=" " />
                                <h3>스토리보드6</h3>
                            </div>
                        </label>
                    </li>
                </ul>
                <footer class="actions">
                    <div class="row">
                        <button type="button" class="load">
                            선택파일 편집
                        </button>
                        <button type="button" class="delete">
                            선택파일 삭제
                        </button>
                        <button type="button" class="new">
                            새로 만들기 시작
                        </button>
                    </div>
                    <div class="row">
                        <button type="button" class="event">
                            선택파일 <strong>스토리보드 공모전</strong> 응모하기
                        </button>
                    </div>
                </footer>
            </section>
            <section
                class="popup share"
                v-bind:class="{ on: showPopups.share }"
                name="share"
            >
                <header class="popup">
                    <h2>스토리워크 공유하기</h2>
                    <button
                        type="button"
                        class="close"
                        @click="showPopups.share = false"
                    >
                        <i></i>닫기
                    </button>
                </header>
                <section>
                    <h3 class="title">{{ this.story.name }}</h3>
                    <p>
                        공유하는 스토리보드는 링크를 받은 누구나에게 공개
                        됩니다.<br />
                        2차 저작물에 대한 책임은 편집자에게 있습니다. 저작권에
                        유의해 주세요.
                    </p>
                    <div class="inputs">
                        <input type="text" v-model="previewUrl" />
                        <select v-if="false">
                            <option>보기</option>
                            <option>공동편집</option>
                        </select>
                        <button type="button" @click="CopyViewURL">복사</button>
                    </div>

                    <ul class="sns" v-if="true">
                        <!-- 사용할 버튼에만 on을 붙여주세요. /pub/images/의 신규 파일도 추가해주세요.-->
                        <li>
                            <button type="button" class="facebook on" @click="popUpFacebook(story.name,previewUrl,story)">
                                <i class="fa-brands fa-facebook-square"></i
                                >페이스북
                            </button>
                        </li>
                        <li>
                            <button type="button" class="twitter on" @click="popUpTwitter(story.name,previewUrl,story)">
                                <i class="fa-brands fa-twitter-square"></i
                                >트위터
                            </button>
                        </li>
                        <li>
                            <button type="button" class="instagram">
                                <i class="fa-brands fa-instagram"></i>인스타그램
                            </button>
                        </li>
                        <li>
                            <button type="button" class="kakaotalk on" @click="kakaoShare(story.name,previewUrl,story)"> 
                            
                            </button>
                        </li>
                        <li>
                            <button type="button" class="kakaostory">
                                카카오스토리
                            </button>
                        </li>
                        <li>
                            <button type="button" class="naverblog">
                                네이버블로그
                            </button>
                        </li>
                    </ul>
                </section>
                <section v-if="false">
                    <h2>스토리워크 다운로드</h2>
                    <p>선택한 스토리보드의 PDF파일을 다운로드 합니다.</p>
                    <button type="button" class="download">PDF 다운로드</button>
                </section>
            </section>
        </div>
    </div>
    <!-- END #content -->
</template>

<style scoped>
@import "/assets/style.css";

@import "/assets/custom.css";
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Black+And+White+Picture&family=East+Sea+Dokdo&family=Gugi&family=Jua&family=Kirang+Haerang&family=Nanum+Brush+Script&family=Nanum+Gothic:wght@400;800&family=Nanum+Myeongjo:wght@400;800&family=Nanum+Pen+Script&family=Noto+Sans+KR:wght@100;900&family=Noto+Serif+KR:wght@200;900&family=Single+Day&family=Yeon+Sung&display=swap");
</style>

<style scoped>
ul {
    margin: 0 0;
    padding: 0 0;
}

.tools > ul > li > button.active {
    opacity: 1;
    color: var(--active);

    border-color: #565 #565 #1e211e #1e211e;
}

.app-content {
    padding: 0 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    height: 100%;
}

.tab-pane {
    width: 360px;
}

.ImagePaneItem {
    width: 100px;
    height: auto;
}

.previewImage {
    width: 100px;
    height: auto;
}
</style>

<style>
#appHeader {
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 60px;
    width: 100%;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    background-color: #fff;
    box-sizing: border-box;
    color: rgba(37, 40, 47, 0.65);
    z-index: 4120;
}

.header_wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(37, 40, 47, 0.1);
}

.header_left {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    justify-content: normal;
}

.header_right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    position: absolute;
    right: 0;
    background-color: #fff;
}

.appContent {
    display: flex;
    flex: 1;
}

#menuSection {
    display: flex;
    flex: 0 0 425px;
    border: solid 1px red;
}
#editorSection {
    display: flex;
}

#previewSection {
    display: flex;
    flex: 0 0 200px;

    border: solid 1px green;
}

#editorContainer {
    width: 100%;
}

#canvasContainer {
    background: #fff;
}

.editorWrapper {
    width: 100%;
    height: calc(100vh - 100px);

    overflow: scroll;
}

.canvas-container {
    border: solid 1px #d3d3d3;
    margin: 10px auto;
}

.previewItem {
    width: 180px;
    height: auto;
    border: solid 1px #e3e3e3;
    margin: 5px 5px;
}

.previewItem.active {
    border: solid 2px #aaffaa;
}
</style>
<script>
import AppOptions from "../config/AppOptions.vue";
import StoryEditor from "../editor/editor";
import { EVENT_NAMES } from "../editor/utils/observable";
import { MMToPt, degreeToRadian } from "../editor/utils/math";

import { Point } from "../editor/utils/point";
import { RGBAColor, RGBColor } from "../editor/utils/color";

import axios from "axios";

import { API_URL } from "../config/config";



export default {
    editor: null,
    props: ["id"],
    metaInfo: {
    title: '스토리워크 에디터',
    link: [{rel: "canonical", href: 'www.storywork.co.kr'}],
    // meta tag 입력하기
  },
    data() {
        return {
            showPopups: {
                layout: false,
                intro: false,
                setCharInit: false,
                setChar: false,
                loadBox: false,
                share: false
            },
            story: {
                name: "제목을 입력해주세요.",
                contents: "",
                previews: [],
                id: -1
            },
            previewUrl: "",
            imageUrl: API_URL,
            isUploading: false,
            uploadImages: [],
            fontList: [
                {
                    value: "'Nanum Gothic', sans-serif",
                    name: "나눔 고딕",
                    id: 1
                },
                {
                    value: "'Nanum Brush Script', cursive",
                    name: "나눔 브러쉬",
                    id: 2
                },

                {
                    value: "'Nanum Myeongjo', serif",
                    name: "나눔 명조",
                    id: 3
                },
                {
                    value: "'Nanum Pen Script', cursive",
                    name: "나눔 손글씨",
                    id: 4
                },
                {
                    value: "'Black And White Picture', sans-serif",
                    name: "흑백사진",
                    id: 5
                },
                {
                    value: "'East Sea Dokdo', cursive",
                    name: "독도",
                    id: 6
                },
                {
                    value: "'Gugi', cursive",
                    name: "구기",
                    id: 7
                },
                {
                    value: "'Jua', sans-serif",
                    name: "주아",
                    id: 8
                },
                {
                    value: "'Kirang Haerang', cursive",
                    name: "기랑 해랑",
                    id: 9
                },
                {
                    value: "'Noto Sans KR', sans-serif",
                    name: "노토 산스",
                    id: 10
                },
                {
                    value: "'Noto Serif KR', serif;",
                    name: "노토 세리프",
                    id: 11
                },
                {
                    value: "'Single Day', cursive",
                    name: "싱글데이",
                    id: 12
                },
                {
                    value: "'Yeon Sung', cursive;",
                    name: "연성",
                    id: 13
                }
            ],
            textProperty: {
                type: "",
                fontSize: 10,
                fontWeight: "normal",
                fontFamily: "'Nanum Gothic', sans-serif",
                underline: false,
                overline: false,
                linethrough: false,
                textAlign: "left",
                fontStyle: "normal", //'' | 'normal' | 'italic' | 'oblique' | undefined;
                //lineHeight: number | undefined;
                textBackgroundColor: "",
                stroke: "",
                shadow: "",
                opacity: 1,
                fill: "",
                //charSpacing: number | undefined;
                //styles?: any;
                //direction?: 'ltr' | 'rtl' | undefined;
                text: ""
                //cacheProperties?: string[] | undefined;
                //stateProperties?: string[] | undefined;
            },
            sideMenu: [
                {
                    name: "레이아웃",
                    id: "template",
                    active: true,
                    show: true
                },
                {
                    name: "등장인물",
                    id: "character",
                    active: false,
                    show: false
                },
                {
                    name: "사진",
                    id: "image",
                    active: false,
                    show: true
                },
                {
                    name: "텍스트",
                    id: "text",
                    active: false,
                    show: true
                },
                {
                    name: "말풍선",
                    id: "bubble",
                    active: false,
                    show: true
                },
                {
                    name: "배경",
                    id: "background",
                    active: false,
                    show: true
                },
                {
                    name: "포즈",
                    id: "pose",
                    active: false,
                    show: true
                },
                {
                    name: "아이템",
                    id: "decorator",
                    active: false,
                    show: true
                }
            ],
            poses: [],
            poseCategory: -1,
            poseCategorys: [],
            poseFilterWord: "",

            backgrounds: [],
            backgroundCategory: -1,
            backgroundCategorys: [],
            backgroundFilterWord: "",

            decorators: [],
            decoratorCategory: -1,
            decoratorCategorys: [],
            decoratorFilterWord: "",

            bubbles: [],
            bubblecategory: -1,
            bubbleCategorys: [],
            bubbleFilterWord: "",

            userImageFilterWord: "",

            pagePreviewImages: []
        };
    },
    created() {
        axios
            .get(API_URL + "posecategory")
            .then(res => {
                this.poseCategorys = res.data;
            })
            .catch(e => {
                console.error("Get poseCategorys Error with : ", e);
            });

        axios
            .get(API_URL + "poses")
            .then(res => {
                this.poses = res.data;
            })
            .catch(e => {
                console.error("Get poses Error with : ", e);
            });

        axios
            .get(API_URL + "backgroundcategory")
            .then(res => {
                this.backgroundCategorys = res.data;
            })
            .catch(e => {
                console.error("Get backgroundCategorys Error with : ", e);
            });

        axios
            .get(API_URL + "backgrounds")
            .then(res => {
                this.backgrounds = res.data;
            })
            .catch(e => {
                console.error("Get backgrounds Error with : ", e);
            });

        axios
            .get(API_URL + "decoratorcategory")
            .then(res => {
                this.decoratorCategorys = res.data;
            })
            .catch(e => {
                console.error("Get decoratorCategorys Error with : ", e);
            });

        axios
            .get(API_URL + "bubbles")
            .then(res => {
                this.bubbles = res.data;
            })
            .catch(e => {
                console.error("Get decorators Error with : ", e);
            });

        axios
            .get(API_URL + "bubblecategory")
            .then(res => {
                this.bubbleCategorys = res.data;
            })
            .catch(e => {
                console.error("Get decoratorCategorys Error with : ", e);
            });

        axios
            .get(API_URL + "decorators")
            .then(res => {
                this.decorators = res.data;
            })
            .catch(e => {
                console.error("Get decorators Error with : ", e);
            });

        axios
            .get(API_URL + "file")
            .then(res => {
                this.uploadImages = res.data;
            })
            .catch(e => {
                console.error("Get decorators Error with : ", e);
            });

        if (this.id) {
            axios
                .get(API_URL + "story/" + this.id)
                .then(res => {
                    this.story = res.data;
                    this.loadEditor();
                })
                .catch(e => {
                    console.error("Get story Error with : ", e);
                });
        }
    },
    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;

        this.editor = new StoryEditor("#editorContainer");

        const there = this;

        this.editor.on(EVENT_NAMES.PREVIEW.UPDATE, e => {
            console.log("EditorVue Preview Update Called : ", e.id);
            const id = e.id;
            //const imageData = e.image;
            const imageData = there.editor.getPreview(id);

            /*
            let targetElement = document.querySelector(
                "#previewContainer > #" + id
            );

            if (!targetElement) {
                targetElement = document.createElement("img");
                targetElement.id = id;
                targetElement.className = "previewItem";

                document
                    .querySelector("#previewContainer")
                    .appendChild(targetElement);
            }

            targetElement.src = imageData;
            */
            const target = there.pagePreviewImages.find(
                preview => preview.id === id
            );

            if (target) {
                target.image = imageData;
                target.active = true;
            } else {
                there.pagePreviewImages.push({
                    id: id,
                    image: imageData,
                    active: false
                });
            }
        });

        this.editor.on(EVENT_NAMES.PAGE.CHANGE, e => {
            /*
            const previews = document.querySelectorAll(
                "#previewContainer > img"
            );

            for (const preview of previews) {
                preview.classList.remove("active");
            }
            let targetElement = document.querySelector(
                "#previewContainer > #" + e.id
            );

            targetElement.classList.add("active");
            */

            there.pagePreviewImages.map(preview => {
                if (preview.id == e.id) preview.active = true;
                else preview.active = false;
            });
        });

        /*
        this.editor.on(EVENT_NAMES.BACKGROUND.CHANGE, e => {
        });
*/
        this.editor.on(EVENT_NAMES.TEXT.CHANGE, e => {
            if (!e.target) return;

            /*
             * @fires changed
             * @fires selection:changed
             * @fires editing:entered
             * @fires editing:exited
             * */

            const target = e.target;
            if (target.type == "textbox") {
                const text = target;

                if (text.isEditing) {
                    const styles = text.getSelectionStyles();
                    console.log("styles : ", styles);
                    /*
        if( styles['fill']){
          const fill = styles['fill'];
         $('input[name="fill"]').val(fill ? fill : '#fff');
        }
        */
                } else {
                    const fill = target.get("fill");
                    if (typeof fill === "string") {
                        //TODO 색상값 반영
                        //$('input[name="fill"]').val(fill ? fill : "#fff");
                    }
                }
            }
        });

        this.editor.on(EVENT_NAMES.OBJECT.CLICK, e => {
            if (!e.selected || e.selected.length < 1) return;

            const target = e.selected[0];

            if (target.type == "textbox") {
                const {
                    type,
                    fontSize,
                    fontWeight,
                    fontFamily,
                    underline,
                    overline,
                    linethrough,
                    textAlign,
                    fontStyle,
                    //lineHeight,
                    textBackgroundColor,
                    stroke,
                    shadow,
                    opacity,
                    fill,
                    //charSpacing,
                    //styles,
                    //direction,
                    text
                } = target;

                there.textProperty.type = type;
                there.textProperty.fontSize = parseFloat(fontSize);
                there.textProperty.fontSize = 30;
                there.textProperty.fontWeight = fontWeight;
                there.textProperty.fontFamily = fontFamily;
                there.textProperty.underline = underline;
                there.textProperty.overline = overline;
                there.textProperty.linethrough = linethrough;
                there.textProperty.textAlign = textAlign;
                there.textProperty.fontStyle = fontStyle;
                there.textProperty.textBackgroundColor = textBackgroundColor;
                there.textProperty.stroke = stroke;
                there.textProperty.shadow = shadow;
                there.textProperty.text = text;
                there.textProperty.opacity = opacity;
                there.textProperty.fill = fill;
            }
        });

        if (typeof this.id === "undefined") this.addPage();
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
        next();
    },
    methods: {
         kakaoShare : function (name,url,story) {           
           window.Kakao.Share.sendCustom({
                templateId: 98196 ,
                templateArgs: {
                    title: name,
                    pageid: story.id,
                    img: API_URL + "file/" + story.previews[0]
                }
            });
           
        },

        popUpTwitter : function (name,url,story) {
            window.open("https://twitter.com/intent/tweet"
                    +"?via=" // 이건 자신의 id 로 꼭 바꿉시다.
                    +"&text="+encodeURIComponent( name ) // Title in this html document
                    +"&url="+encodeURIComponent(url)
                    +"&image="+API_URL + "file/" + story.previews[0]
            );
        },
        popUpFacebook : function (name,url) {
            var url2 = "http://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(url)+"&t="+name;
            window.open(url2, "", "width=486, height=286");
        },
        filterPose: function() {
            if (this.poseFilterWord.length > 0) {
                this.poseCategory = -1;
                const ret = this.poses.filter(item => {
                    let index = item.name.indexOf(this.poseFilterWord);

                    if (index < 0) {
                        return item.tags.some(tag => {
                            return tag.indexOf(this.poseFilterWord) > -1;
                        });
                    }
                    return true;
                });
                return ret;
            } else {
                if (this.poseCategory > -1) {
                    const ret = this.poses.filter(item => {
                        return item.category == this.poseCategory;
                    });
                    return ret;
                }
                return this.poses;
            }
        },
        poseCategoryChange: function() {
            this.poseFilterWord = "";
        },

        filterBackground: function() {
            if (this.backgroundFilterWord.length > 0) {
                this.backgroundCategory = -1;
                const ret = this.backgrounds.filter(item => {
                    let index = item.name.indexOf(this.backgroundFilterWord);

                    if (index < 0) {
                        return item.tags.some(tag => {
                            return tag.indexOf(this.backgroundFilterWord) > -1;
                        });
                    }
                    return true;
                });
                return ret;
            } else {
                if (this.backgroundCategory > -1) {
                    const ret = this.backgrounds.filter(item => {
                        return item.category == this.backgroundCategory;
                    });
                    return ret;
                }
                return this.backgrounds;
            }
        },
        backgroundCategoryChange: function() {
            this.backgroundFilterWord = "";
        },

        filterUserImage: function() {
            if (this.userImageFilterWord.length > 0) {
                const ret = this.uploadImages.filter(item => {
                    let index = item.original_name.indexOf(
                        this.userImageFilterWord
                    );

                    return index > -1;
                });
                return ret;
            }

            return this.uploadImages;
        },

        filterDecorator: function() {
            if (this.decoratorFilterWord.length > 0) {
                this.decoratorCategory = -1;
                const ret = this.decorators.filter(item => {
                    let index = item.name.indexOf(this.decoratorFilterWord);

                    if (index < 0) {
                        return item.tags.some(tag => {
                            return tag.indexOf(this.decoratorFilterWord) > -1;
                        });
                    }
                    return true;
                });
                return ret;
            } else {
                if (this.decoratorCategory > -1) {
                    const ret = this.decorators.filter(item => {
                        return item.category == this.decoratorCategory;
                    });
                    return ret;
                }
                return this.decorators;
            }
        },
        decoratorCategoryChange: function() {
            this.decoratorFilterWord = "";
        },

        menuClick: function(id) {
            const targetMenu = this.sideMenu.filter(m => m.id == id);
            if (targetMenu.length > 0) {
                this.sideMenu.map(m => (m.active = false));
                targetMenu[0].active = true;
            }
        },
        isMenuActive: function(id) {
            const targetMenu = this.sideMenu.filter(m => m.id == id);
            if (targetMenu.length > 0) {
                return targetMenu[0].active;
            }
        },
        isMenuShow: function(id) {
            const targetMenu = this.sideMenu.filter(m => m.id == id);
            if (targetMenu.length > 0) {
                return targetMenu[0].show;
            }
            return false;
        },
        addText: function() {
            this.editor.addText();
        },
        addPage: function() {
            /*
          A0    =  841  x 1189
          A1    =  594  x  841
          A2    =  420  x  594
          A3    =  297  x  420
          A4    =  210  x  297
          A5    =  148  x  210
          A6    =  105  x  148
          A7    =   74  x  105
          A8    =   52  x   74
          A9    =   37  x   52
          A10    =   26  x   37
        */

            const pageWidth = MMToPt(297);
            const pageHeight = MMToPt(210);
            const pageMargin = MMToPt(2);

            this.editor.addPage({
                width: pageWidth,
                height: pageHeight,
                bleed: {
                    left: pageMargin,
                    top: pageMargin,
                    right: pageMargin,
                    bottom: pageMargin
                }
            });
        },
        onFontNameChange: function() {
            this.editor.setStyle("fontFamily", this.textProperty.fontFamily);
        },

        onFontSizeChange: function(e) {
            console.log(this.editor);
            this.editor.setStyle("fontSize", e.target.value);
        },

        onPropertyChange: function(e) {
            const element = e.target;
            // if (element.type == "range") {
            //     return;
            // }

            let name = element.name;
            if (name.length < 1) return;

            let value = element.value;
            if (element.type == "checkbox") {
                if (element.checked == false) {
                    value = element.getAttribute("false-value") || "";
                }
            }

            if (name.indexOf("shadow") > -1) {
                name = "shadow";
                let colorValue = document
                    .querySelector(".library_contents input[name=shadow-color]")
                    .val();
                let opacityValue = document
                    .querySelector(
                        ".library_contents input[name=shadow-opacity]"
                    )
                    .val();
                let angleValue = document
                    .querySelector(
                        '.library_contents input[name="shadow-angle"]'
                    )
                    .val();
                let widthValue = document
                    .querySelector(
                        '.library_contents input[name="shadow-width"]'
                    )
                    .val();
                let blurValue = document
                    .querySelector(".library_contents input[name=shadow-blur]")
                    .val();

                //console.log(`shadow color : ${colorValue}`);

                const rgb = RGBColor.parse(colorValue);
                let color = new RGBAColor(
                    rgb.red,
                    rgb.green,
                    rgb.blue,
                    parseFloat(opacityValue)
                );

                let angle = angleValue ? parseInt(angleValue) : 0;
                let width = widthValue ? parseInt(widthValue) : 0;
                let blur = blurValue ? parseInt(blurValue) : 0;
                let pt = new Point(width, 0).rotate(degreeToRadian(angle));

                console.log(`angle : ${angle}, pt : ${pt}`);

                value = {
                    color: color ? color.toString() : "rgba(0,0,0,1)",
                    offsetX: pt.x,
                    offsetY: pt.y,
                    blur: blur
                };
            }

            this.editor.setStyle(name, value);
        },

        uploadFile() {
            if (0 < this.$refs.selectFile.files.length) {
                const targetFile = this.$refs.selectFile.files[0];
                this.isUploading = true;
                let form = new FormData();
                form.append("type", "user");
                form.append("file", targetFile);

                const there = this;

                axios
                    .post("file/upload/", form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(res => {
                        there.response = res;
                        there.isUploading = false;

                        there.uploadImages.push(res.data.file);
                    })
                    .catch(error => {
                        there.response = error;
                        there.isUploading = false;
                    });
            }
        },

        imageDragStart(e, type) {
            console.log("imageDragStart id : ", e.currentTarget.id);
            if (type == "pose") type = "image";
            else if (type == "bubble") type = "image";
            const data = {
                type: type,
                data: { id: e.currentTarget.id, src: e.currentTarget.src }
            };

            e.dataTransfer?.setData("text/plain", JSON.stringify(data));
        },

        SaveStory() {
            const contents = this.editor.save();
            const previewImages = this.editor.saveToImage();

            this.story.contents = JSON.stringify(contents);
            this.story.previews = [];

            const {
                // eslint-disable-next-line no-unused-vars
                id,
                // eslint-disable-next-line no-unused-vars
                previews,
                // eslint-disable-next-line no-unused-vars
                created_at,
                // eslint-disable-next-line no-unused-vars
                updated_at,
                ...data
            } = this.story;
            const there = this;

            this.isUploading = true;

            let form = new FormData();
            form.append("type", "preview");
            for (let index = 0; index < previewImages.length; index++) {
                const image = previewImages[index];
                form.append(`files[]`, image, `${index}.png`);
            }

            /*
                axios
                    .post("file/uploads/", form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(res => {
                        there.isUploading = false;

                        console.log("upload previews result : ", res);
                    })
                    .catch(error => {
                        console.log("story save preview fail with ", error);
                        there.isUploading = false;
                    });
            */

            if (this.story.id > -1) {
                axios
                    .put("story/" + this.story.id, data)
                    .then(() => {
                        there.isUploading = false;
                        //there.makeToast("저장되었습니다.");

                        there.uploadPreviews();
                    })
                    .catch(err => {
                        console.log("dddd");
                        console.log("story save fail with ", err);
                        this.isUploading = false;
                        there.makeToast("실패하였습니다.");
                    });
            } else {
                axios
                    .post("story", data)
                    .then(res => {
                        there.isUploading = false;
                        there.story = res.data;
                        //there.makeToast("저장되었습니다.");
                        there.uploadPreviews();
                    })
                    .catch(err => {
                        console.log("story save fail with ", err);
                        there.isUploading = false;
                        there.makeToast("실패하였습니다.");
                    });
            }
        },

        uploadPreviews() {
            const previews = this.editor.saveToImage();

            const there = this;

            this.isUploading = true;

            let form = new FormData();
            form.append("type", "preview");
            form.append("id", this.story.id);

            for (let index = 0; index < previews.length; index++) {
                const preview = previews[index];
                form.append(`files[]`, preview, `${index}.png`);
            }

            axios
                .post("file/uploads/", form, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    there.isUploading = false;

                    console.log("upload previews result : ", res);

                    there.story.previews = [
                        ...new Set(res.data.map(item => item.seq + ""))
                    ];

                    // eslint-disable-next-line no-unused-vars
                    const {
                        // eslint-disable-next-line no-unused-vars
                        id,

                        // eslint-disable-next-line no-unused-vars
                        created_at,
                        // eslint-disable-next-line no-unused-vars
                        updated_at,
                        ...data
                    } = this.story;

                    axios
                        .put("story/" + id, data)
                        .then(() => {
                            this.isUploading = false;
                            there.makeToast("저장되었습니다.");
                        })
                        .catch(err => {
                            console.log("story save fail with ", err);
                            this.isUploading = false;
                            there.makeToast("실패하였습니다.");
                        });
                })
                .catch(error => {
                    console.log("story save preview fail with ", error);
                    there.isUploading = false;
                });
        },
        makeToast(msg) {
            this.$notify({
                group: "notifyApp",
                title: "알림",
                text: msg
            });
        },
        uploadFileClick() {
            this.$refs.selectFile.click();
        },
        deleteUserImage(e, userImage) {
            if (!window.confirm("복구할수 없습니다. 삭제하시겠습니까?")) return;

            const there = this;

            axios
                .delete(`file/${userImage.seq}`)
                .then(function(res) {
                    console.log("reuslt : ", res.data);
                    there.uploadImages.splice(
                        there.uploadImages.findIndex(
                            item => item.seq == userImage.seq
                        ),
                        1
                    );

                    there.makeToast("Success");
                })
                .catch(function(err) {
                    console.log(" error : ", err);
                    there.makeToast("Fail");
                });
        },
        loadEditor() {
            this.editor.load(this.story.contents);
        },
        moveStorage() {
            this.$router.push("/storage");
        },
        moveHome() {
            if (
                !window.confirm(
                    "저장되지 않은 내용은 삭제됨니다. 이동하시겠습니까?"
                )
            )
                return;
            this.$router.push("/");
        },
        deletePage(pageId) {
            const targetIndex = this.pagePreviewImages.findIndex(
                preview => preview.id == pageId
            );
            if (targetIndex > -1) {
                if (this.editor.deletePage(pageId)) {
                    this.pagePreviewImages.splice(targetIndex, 1);
                }
            }
        },
        setActivePage(pageId) {
            const targetIndex = this.pagePreviewImages.findIndex(
                preview => preview.id == pageId
            );

            this.pagePreviewImages.map(preview => {
                if (preview.id == pageId) preview.active = true;
                else preview.active = false;
            });
            if (targetIndex > -1) {
                this.editor.setActivePage(pageId);
            }
        },
        duplicateObject() {
            this.editor.duplicateObject();
        },

        moveForward() {
            this.editor.bringForward();
        },
        moveBack() {
            this.editor.sendBackwards();
        },
        moveFisrt() {
            this.editor.bringToFront();
        },
        moveLast() {
            this.editor.sendToBack();
        },
        flipHor() {
            this.editor.flipX();
        },
        flipVer() {
            this.editor.flipY();
        },
        removeObject() {
            this.editor.removeSelectedObject();
        },
        shareStory() {
            if (this.story.id > -1) {
                this.MakePreviewUrl();
                this.showPopups.share = true;
            } else {
                this.makeToast("저장 먼저 진행해주세요.");
            }
        },
        shareStory2() {            

                if (!window.confirm("스토리를 모두에게 공유하시겠습니까?")) return;

                 const there = this;

                axios
                    .put("story/" + this.story.id + "/gshared")
                    .then(() => {
                        there.isUploading = false;
                        there.makeToast("저장되었습니다.");
                        this.$router.go();
                        //there.uploadPreviews();
                    })
                    .catch(err => {
                        console.log("story save fail with ", err);
                        this.isUploading = false;
                        there.makeToast("실패하였습니다.");
                    });
                

          
        },
        shareStory3() {            

                if (!window.confirm("스토리 공유를 취소하겠습니까?")) return;

                 const there = this;

                axios
                    .put("story/" + this.story.id + "/gshared2")
                    .then(() => {
                        there.isUploading = false;
                        there.makeToast("저장되었습니다.");
                        this.$router.go();
                        //there.uploadPreviews();   
                    })
                    .catch(err => {
                        console.log("story save fail with ", err);
                        this.isUploading = false;
                        there.makeToast("실패하였습니다.");
                    });
                
          
        },
        getFileName(contentDisposition) {
            let fileName = contentDisposition
                .split(";")
                .filter(ele => {
                    return ele.indexOf("fileName") > -1;
                })
                .map(ele => {
                    return ele.replace(/"/g, "").split("=")[1];
                });
            return fileName[0] ? fileName[0] : null;
        },
        downloadStory() {
            if (this.story.id > -1) {
                axios
                    .get(`story/${this.story.id}/download`, {
                        responseType: "blob"
                    })
                    .then(res => {
                        const url = window.URL.createObjectURL(
                            new Blob([res.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "download.zip"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(err => {
                        console.log("file download error ", err);
                    });
            } else {
                this.makeToast("저장 먼저 진행해주세요.");
            }
        },
        CheckShowPopup() {
            let result = false;
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.showPopups)) {
                if (value) {
                    result = value;
                }
            }

            return result;
        },
        MakePreviewUrl() {
            if (this.story.id > 0) {
                this.previewUrl =
                    window.location.origin +
                    "/editor/" +
                    this.story.id +
                    "/preview";
            } else {
                this.previewUrl = "";
            }
        },
        CopyViewURL() {
            const there = this;
            this.$copyText(this.previewUrl).then(
                function() {
                    there.makeToast("복사되었습니다.");
                },
                function() {
                    there.makeToast("실패했습니다.");
                }
            );
        }
    }
};
</script>

