import { xy2angle } from './math';

export class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  clone() {
    return new Point(this.x, this.y);
  }

  offset(x, y) {
    this.x += x;
    this.y += y;

    return this;
  }

  add(pt) {
    this.x += pt.x;
    this.y += pt.y;
    return this;
  }

  subtract(pt) {
    this.x -= pt.x;
    this.y -= pt.y;
    return this;
  }

  multiply(pt) {
    if (typeof pt !== 'number') {
      this.x *= pt.x;
      this.y *= pt.y;
    } else {
      this.x *= pt;
      this.y *= pt;
    }
    return this;
  }

  divide(pt) {
    if (typeof pt !== 'number') {
      this.x /= pt.x;
      this.y /= pt.y;
    } else {
      this.x /= pt;
      this.y /= pt;
    }

    return this;
  }

  eq(pt) {
    return this.x == pt.x && this.y == pt.y;
  }

  lt(pt) {
    return this.x < pt.x && this.y < pt.y;
  }

  lte(pt) {
    return this.x <= pt.x && this.y <= pt.y;
  }

  gt(pt) {
    return this.x > pt.x && this.y > pt.y;
  }

  gte(pt) {
    return this.x >= pt.x && this.y >= pt.y;
  }

  lerp(pt, t) {
    return new Point(this.x + (pt.x - this.x) * t, this.y + (pt.y - this.y) * t);
  }

  distanceFrom(pt) {
    var dx = this.x - pt.x,
      dy = this.y - pt.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  min(pt) {
    return new Point(Math.min(this.x, pt.x), Math.min(this.y, pt.y));
  }

  max(pt) {
    return new Point(Math.max(this.x, pt.x), Math.max(this.y, pt.y));
  }

  toString() {
    return this.x + ',' + this.y;
  }

  setXY(x, y) {
    this.x = x;
    this.y = y;

    return this;
  }

  setFromPoint(pt) {
    this.x = pt.x;
    this.y = pt.y;
  }

  swap(pt) {
    let x = this.x,
      y = this.y;

    this.x = pt.x;
    this.y = pt.y;
    pt.x = x;
    pt.y = y;
  }

  rotate(radian) {
    var cos_a = Math.cos(radian);
    var sin_a = Math.sin(radian);

    return new Point(this.x * cos_a - this.y * sin_a, this.x * sin_a + this.y * cos_a);
  }

  translate(x, y) {
    return this.offset(x, y);
  }

  scale(w, h) {
    return this.multiply(new Point(w, h));
  }

  angle(center, pt) {
    if (pt) {
      this.translate(-center.x, -center.y);
      pt.translate(-center.x, -center.y);

      var s = xy2angle(this.x, this.y);
      var e = xy2angle(pt.x, pt.y);
      var angle = Math.abs(e - s);

      if (angle < Math.PI) return angle;
      else return Math.PI * 2 - angle;
    } else {
      this.translate(-center.x, -center.y);
      return xy2angle(this.x, this.y);
    }
  }

  rotate_at(center, radian) {
    this.translate(-center.x, -center.y);
    this.rotate(radian);
    this.translate(center.x, center.y);

    return this;
  }
}

export function getElementOffset(element) {
  var de = document.documentElement;
  var box = element.getBoundingClientRect();
  var top = box.top + window.pageYOffset - de.clientTop;
  var left = box.left + window.pageXOffset - de.clientLeft;
  return { top: top, left: left };
}
