var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app",class:{
        'app-sidebar-minified': _vm.appOptions.appSidebarMinified,
        'app-sidebar-mobile-toggled': _vm.appOptions.appSidebarMobileToggled,
        'app-sidebar-mobile-closed': _vm.appOptions.appSidebarMobileClosed,
        'app-content-full-height': _vm.appOptions.appContentFullHeight,
        'app-content-full-width': _vm.appOptions.appContentFullWidth,
        'app-without-sidebar': _vm.appOptions.appWithoutSidebar,
        'pt-0': _vm.appOptions.appWithoutHeader,
        'app-boxed-layout': _vm.appOptions.appBoxedLayout,
        'app-footer-fixed': _vm.appOptions.appWithFooter
    },attrs:{"id":"app"}},[_c('notifications',{attrs:{"group":"notifyApp","position":"top right","type":"warn"}}),(!_vm.appOptions.appWithoutHeader)?_c('Header'):_vm._e(),(!_vm.appOptions.appWithoutSidebar)?_c('Sidebar'):_vm._e(),_c('router-view'),(_vm.appOptions.appWithFooter)?_c('Footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }