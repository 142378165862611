import axios from "axios";
import { API_URL } from "../config/config";

import store from "../store";

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(
    function(config) {
        const token = store.getters.token;
        config.headers.common["Authorization"] = "Bearer " + token;
        config.headers['Access-Control-Allow-Origin'] = '*';
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

/*
axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        return Promise.reject(error);
    }
);
*/

export default axios;
