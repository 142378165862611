<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-3">
            <div>
                <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="javascript:;">ADMIN</a>
                    </li>
                    <li class="breadcrumb-item active">USERS</li>
                </ul>
                <h1 class="page-header mb-0">Users</h1>
            </div>

            <div class="ms-auto">
                <button
                    type="button"
                    class="btn btn-primary me-2"
                    v-b-modal.userModal
                >
                    <i class="fa fa-plus-circle fa-fw me-1"></i> Create User
                </button>

                <b-modal
                    id="userModal"
                    title="Create User"
                    no-close-on-backdrop
                    @hidden="resetModal"
                    @ok="handleOk"
                >
                    <form v-on:submit="checkForm" name="register_form">
                        <div class="mb-3">
                            <label class="form-label"
                                >Name <span class="text-danger">*</span></label
                            >
                            <b-input
                                size="lg"
                                class="fs-15px"
                                placeholder="Name place"
                                name="username"
                                v-model="user.userName"
                            ></b-input>
                        </div>
                        <div class="mb-3">
                            <label class="form-label"
                                >Email Address
                                <span class="text-danger">*</span></label
                            >
                            <b-input
                                type="email"
                                size="lg"
                                autocomplete="username"
                                class="fs-15px"
                                placeholder="username@address.com"
                                v-model="user.userId"
                            ></b-input>
                        </div>
                        <div v-if="user.seq == -1" class="mb-3">
                            <label class="form-label"
                                >Password
                                <span class="text-danger">*</span></label
                            >
                            <b-input
                                type="password"
                                autocomplete="new-password"
                                size="lg"
                                class="fs-15px"
                                v-model="user.password"
                            ></b-input>
                        </div>
                        <div v-if="user.seq == -1" class="mb-3">
                            <label class="form-label"
                                >Confirm Password
                                <span class="text-danger">*</span></label
                            >
                            <b-input
                                type="password"
                                autocomplete="new-password"
                                size="lg"
                                class="fs-15px"
                                v-model="passwordConfirm"
                            ></b-input>
                        </div>
                    </form>
                </b-modal>
            </div>
        </div>

        <!--
        <div class="mb-md-4 mb-3 d-md-flex">
            <div class="mt-md-0 mt-2">
                <a href="javascript:;" class="text-dark text-decoration-none"
                    ><i class="fa fa-download fa-fw me-1 text-muted"></i>
                    Export</a
                >
            </div>
            <div class="ms-md-4 mt-md-0 mt-2">
                <b-dropdown
                    toggle-class="text-dark text-decoration-none p-0 shadow-none"
                    variant="link"
                    no-caret
                >
                    <template #button-content>
                        More Actions <b class="caret"></b>
                    </template>
                    <b-dropdown-item>First Action</b-dropdown-item>
                    <b-dropdown-item>Second Action</b-dropdown-item>
                    <b-dropdown-item>Third Action</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item active>Active action</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        -->

        <div class="card">
            <div class="tab-content p-4">
                <div class="tab-pane fade show active" id="allTab">
                    <!-- BEGIN input-group -->
                    <div class="input-group mb-4">
                        <div class="flex-fill position-relative">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control ps-35px"
                                    placeholder="Filter"
                                />
                                <div
                                    class="input-group-text position-absolute top-0 bottom-0 bg-none border-0"
                                    style="z-index: 1020;"
                                >
                                    <i class="fa fa-search opacity-5"></i>
                                </div>
                            </div>
                        </div>
                        <!--
                        <button
                            class="btn btn-default dropdown-toggle rounded-0"
                            type="button"
                            data-bs-toggle="dropdown"
                        >
                            <span class="d-none d-md-inline"
                                >Payment Status</span
                            ><span class="d-inline d-md-none"
                                ><i class="fa fa-credit-card"></i
                            ></span>
                            &nbsp;
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:;"
                                >Action</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                                >Another action</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                                >Something else here</a
                            >
                            <div
                                role="separator"
                                class="dropdown-divider"
                            ></div>
                            <a class="dropdown-item" href="javascript:;"
                                >Separated link</a
                            >
                        </div>
                        <button
                            class="btn btn-default dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                        >
                            <span class="d-none d-md-inline"
                                >Fulfillment status</span
                            ><span class="d-inline d-md-none"
                                ><i class="fa fa-check"></i
                            ></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="javascript:;"
                                >Action</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                                >Another action</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                                >Something else here</a
                            >
                            <div
                                role="separator"
                                class="dropdown-divider"
                            ></div>
                            <a class="dropdown-item" href="javascript:;"
                                >Separated link</a
                            >
                        </div>
                        -->
                    </div>
                    <!-- END input-group -->

                    <!-- BEGIN table -->
                    <div class="table-responsive">
                        <b-table
                            striped
                            hover
                            data-pagination="true"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="users"
                            :small="true"
                            :fields="fields"
                            :busy.sync="requesting"
                            :filter="searchWord"
                            :filter-included-fields="filterOn"
                        >
                            <template v-slot:cell(Action)="row">
                                <b-button
                                    variant="info"
                                    size="sm"
                                    @click="modify(row)"
                                    class="ml-1"
                                    >Modify</b-button
                                >
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    @click="remove(row)"
                                    class="ml-1"
                                    >Delete</b-button
                                >
                            </template>
                        </b-table>
                    </div>
                    <!-- END table -->
                    <div>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- END #content -->
</template>

<script>
import "../../scss/styles.scss";
import AppOptions from "../../config/AppOptions.vue";
import { dateFormater } from "../../util/date";
import axios from "axios";
import { API_URL } from "../../config/config";

export default {
    data: function() {
        return {
            perPage: 8,
            currentPage: 1,
            totalRows: 0,
            users: [],
            user: {
                seq: -1,
                userName: "",
                userId: "",
                password: "",
                role: "user"
            },
            passwordConfirm: "",
            requesting: false,
            searchWord: null,
            filterOn: ["userName"],
            fields: [
                { key: "seq", sortable: true },
                { key: "userId", sortable: true },
                { key: "userName", sortable: true },
                { key: "role", sortable: true },
                {
                    key: "created_at",
                    label: "생성시간",
                    sortable: true,
                    formatter: value => {
                        const curDate = new Date(value);
                        return dateFormater("yyyy-mm-dd H:i:s", curDate);
                    }
                },
                {
                    key: "updated_at",
                    label: "갱신시간",
                    sortable: true,
                    formatter: value => {
                        const curDate = new Date(value);
                        return dateFormater("yyyy-mm-dd H:i:s", curDate);
                    }
                },
                { key: "Action", sortable: false, label: "Action" }
            ]
        };
    },
    mounted() {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
    },

    created() {
        /*
        const token = this.$store.getters.token;
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
*/
        axios
            .get(API_URL + "users")
            .then(res => {
                this.users = res.data;
                this.totalRows = this.users.length;
            })
            .catch(e => {
                console.error("Get Users Error with : ", e);
            });

        /*
        this.$store.dispatch("getUsers").then(
            data => {
                this.users = data;
            },
            error => {
                console.error("Get Users Error with : ", error);
            }
        );
        */
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;
        next();
    },
    methods: {
        resetModal() {
            this.passwordConfirm = "";
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.checkForm();
        },
        checkForm: function() {
            const there = this;
            if (this.user.seq > -1) {
                // eslint-disable-next-line no-unused-vars
                const { seq, created_at, updated_at, ...data } = this.user;

                const prevData = JSON.parse(JSON.stringify(this.user));

                axios
                    .put("users/" + seq, data)
                    .then(res => {
                        console.log("reuslt : ", res.data);

                        const index = there.users.findIndex(
                            item => item.seq == seq
                        );
                        there.$set(there.users, index, prevData);

                        there.makeToast("Success");

                        there.$bvModal.hide("userModal");
                    })
                    .catch(err => {
                        console.log("User modify error : ", err);

                        there.makeToast("Fail");
                    });
            } else {
                // eslint-disable-next-line no-unused-vars
                const { seq, ...data } = this.user;
                axios
                    .post("users/", data)
                    .then(res => {
                        console.log("reuslt : ", res.data);

                        there.users.push(res.data);

                        there.makeToast("Success");

                        there.$bvModal.hide("userModal");
                    })
                    .catch(err => {
                        console.log("User create error : ", err);

                        there.makeToast("Fail");
                    });
            }
        },
        makeToast(msg) {
            this.$notify({
                group: "notifyApp",
                title: "알림",
                text: msg
            });
        },

        modify(row) {
            this.user = { ...row.item };
            this.$bvModal.show("userModal");
        },

        remove(row) {
            if (!window.confirm("복구할수 없습니다. 삭제하시겠습니까?")) return;

            const there = this;

            axios
                .delete(`users/${row.item.seq}`)
                .then(function(res) {
                    console.log("reuslt : ", res.data);
                    there.users.splice(
                        there.users.findIndex(item => item.seq == row.item.seq),
                        1
                    );

                    there.makeToast("Success");
                })
                .catch(function(err) {
                    console.log("User delete error : ", err);
                    there.makeToast("Fail");
                });
        }
    }
};
</script>
