<script>
const appSidebarMenu = [
    {
        text: "Navigation",
        is_header: true
    },
    {
        url: "/admin/users",
        icon: "fa fa-user",
        text: "User"
    },
    {
        url: "/admin/poses",
        icon: "fa fa-home",
        text: "Pose"
    },
    {
        url: "/admin/posecategory",
        icon: "fa fa-home",
        text: "Pose Category"
    },
    {
        url: "/admin/backgrounds",
        icon: "fa fa-file-image",
        text: "Background"
    },
    {
        url: "/admin/backgroundcategory",
        icon: "fa fa-file-image",
        text: "Background Category"
    },

    {
        url: "/admin/decos",
        icon: "fa fa-tags",
        text: "Decorators"
    },
    {
        url: "/admin/decocategory",
        icon: "fa fa-tags",
        text: "Decorator Category"
    },
    {
        url: "/admin/bubbles",
        icon: "fa fa-commenting-o",
        text: "말풍선"
    },
    {
        url: "/admin/bubblecategory",
        icon: "fa fa-commenting-o",
        text: "말풍선 Category"
    },
    {
        url: "/admin/contest",
        icon: "fa fa-tags",
        text: "Contest"
    },
    {
        url: "/admin/exhibits",
        icon: "fa fa-tags",
        text: "Exhibits"
    }
];

export default appSidebarMenu;
</script>
